import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const AdminNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        if (!token) {
          console.error('No token found');
          setLoading(false);
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/audio-samples/admin-stats`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.data.success) {
          // Create notifications based on pending samples
          const pendingCount = response.data.data.pending;
          
          if (pendingCount > 0) {
            setNotifications([
              {
                id: 'pending-samples',
                message: `${pendingCount} audio sample${pendingCount > 1 ? 's' : ''} pending review`,
                type: 'warning',
                link: '/admin/audio-samples?status=pending',
                time: new Date()
              }
            ]);
          } else {
            setNotifications([]);
          }
        }
        
        setLoading(false);
      } catch (error) {
        console.error('Error fetching admin notifications:', error);
        setError('Failed to load notifications');
        setLoading(false);
      }
    };

    if (currentUser?.role === 'admin') {
      fetchNotifications();
      
      // Poll for new notifications every 5 minutes
      const intervalId = setInterval(fetchNotifications, 300000);
      
      return () => clearInterval(intervalId);
    } else {
      setLoading(false);
    }
  }, [currentUser]);

  if (loading) {
    return (
      <div className="bg-white/5 backdrop-blur-sm rounded-lg p-4 animate-pulse">
        <div className="h-5 bg-white/20 rounded w-3/4 mb-2"></div>
        <div className="h-4 bg-white/20 rounded w-1/2"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-500/20 backdrop-blur-sm border border-red-500/30 text-white rounded-lg p-4">
        {error}
      </div>
    );
  }

  if (notifications.length === 0) {
    return (
      <div className="bg-white/5 backdrop-blur-sm rounded-lg p-4 text-white/70">
        No new notifications
      </div>
    );
  }

  return (
    <div className="bg-white/5 backdrop-blur-sm rounded-lg p-4">
      <h4 className="text-lg font-semibold text-white mb-3">Notifications</h4>
      <div className="space-y-3">
        {notifications.map((notification) => (
          <Link 
            key={notification.id}
            to={notification.link}
            className={`block p-3 rounded-md ${
              notification.type === 'warning' 
                ? 'bg-orange-500/20 border border-orange-500/30' 
                : notification.type === 'success'
                ? 'bg-green-500/20 border border-green-500/30'
                : 'bg-blue-500/20 border border-blue-500/30'
            }`}
          >
            <div className="flex items-start justify-between">
              <div>
                <p className="text-white font-medium">{notification.message}</p>
                <p className="text-white/60 text-sm">
                  {new Date(notification.time).toLocaleString()}
                </p>
              </div>
              <span className="text-white/80">→</span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AdminNotifications;
