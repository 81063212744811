import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import './AudioSamples.css';
import { Link } from 'react-router-dom';

const AudioSampleDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [audioSample, setAudioSample] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [feedback, setFeedback] = useState('');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchAudioSample = async () => {
      try {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        const headers = token ? { Authorization: `Bearer ${token}` } : {};

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/audio-samples/${id}`,
          { headers }
        );

        if (response.data.success) {
          setAudioSample(response.data.data);
        } else {
          setError('Failed to load audio sample');
        }
      } catch (error) {
        console.error('Error fetching audio sample:', error);
        setError(error.response?.data?.message || 'Error loading audio sample');
      } finally {
        setLoading(false);
      }
    };

    fetchAudioSample();
  }, [id]);

  const handleVote = async (voteType) => {
    try {
      setSubmitting(true);
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      
      // Convert voteType to number (1 for upvote, -1 for downvote)
      const voteValue = voteType === 'upvote' ? 1 : -1;
      
      // Same API call for both guests and authenticated users
      const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
      
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/audio-samples/${id}/vote`,
        { vote: voteValue },
        config
      );
      
      if (response.data.success) {
        setAudioSample({
          ...audioSample,
          votes: response.data.data.votes
        });
      }
    } catch (error) {
      console.error('Error voting on audio sample:', error);
      setError(error.response?.data?.message || 'Error voting on sample');
    } finally {
      setSubmitting(false);
    }
  };

  const handleStatusUpdate = async (newStatus) => {
    try {
      setSubmitting(true);
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      
      if (!token) {
        setError('Authentication required');
        return;
      }
      
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/audio-samples/${id}/status`,
        { 
          status: newStatus,
          feedback: feedback 
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      if (response.data.success) {
        setAudioSample({
          ...audioSample,
          status: newStatus,
          adminFeedback: feedback
        });
        setFeedback('');
      }
    } catch (error) {
      console.error('Error updating audio sample status:', error);
      setError(error.response?.data?.message || 'Error updating status');
    } finally {
      setSubmitting(false);
    }
  };

  const getStatusBadge = (status) => {
    switch (status) {
      case 'pending':
        return <span className="status-badge pending">Pending Review</span>;
      case 'approved':
        return <span className="status-badge approved">Approved</span>;
      case 'rejected':
        return <span className="status-badge rejected">Rejected</span>;
      case 'in_game':
        return <span className="status-badge in-game">In Game</span>;
      default:
        return <span className="status-badge">{status}</span>;
    }
  };

  if (loading) {
    return (
      <div className="audio-sample-details loading">
        <div className="loading-spinner"></div>
        <p>Loading audio sample...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="audio-sample-details error">
        <div className="error-message">
          <p>{error}</p>
          <button onClick={() => navigate(-1)}>Go Back</button>
        </div>
      </div>
    );
  }

  if (!audioSample) {
    return (
      <div className="audio-sample-details error">
        <div className="error-message">
          <p>Audio sample not found</p>
          <button onClick={() => navigate(-1)}>Go Back</button>
        </div>
      </div>
    );
  }

  return (
    <div className="audio-sample-details">
      <div className="details-container">
        <div className="details-header">
        {/* Logo and Title START */}
        <div className="flex flex-col items-center mb-2">
          <Link to="/" className="hover:opacity-100 transition-opacity">
            <img src="/images/logo5.png" alt="LangGuesser Logo" className="h-32 w-32" />
          </Link>
          <div className="text-center">
            <Link to="/" className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-200 hover:from-blue-400 hover:to-green-300 transition-colors">
              LangGuesser's
            </Link>
          </div>
        </div>
        {/* Logo and Title END */}
          <h2>Audio Sample Details</h2>
          <button className="back-button" onClick={() => navigate(-1)}>
            &larr; Back
          </button>
        </div>

        <div className="audio-player-container">
          <iframe 
            title="Vocaroo audio player"
            src={audioSample.audioUrl} 
            frameBorder="0" 
            allow="autoplay"
          ></iframe>
        </div>

        <div className="sample-info">
          <div className="info-row">
            <div className="info-label">Status:</div>
            <div className="info-value">{getStatusBadge(audioSample.status)}</div>
          </div>
          
          <div className="info-row">
            <div className="info-label">Language:</div>
            <div className="info-value">{audioSample.language}</div>
          </div>
          
          <div className="info-row">
            <div className="info-label">Country:</div>
            <div className="info-value">{audioSample.country}</div>
          </div>
          
          {audioSample.dialect && (
            <div className="info-row">
              <div className="info-label">Dialect:</div>
              <div className="info-value">{audioSample.dialect}</div>
            </div>
          )}
          
          <div className="info-row">
            <div className="info-label">Submitted:</div>
            <div className="info-value">
              {new Date(audioSample.submittedAt).toLocaleString()}
            </div>
          </div>
          
          <div className="info-row">
            <div className="info-label">Contributor:</div>
            <div className="info-value">{audioSample.userId?.nickname || 'Anonymous'}</div>
          </div>
          
          {audioSample.transcription && (
            <div className="info-row">
              <div className="info-label">Transcription:</div>
              <div className="info-value transcription">{audioSample.transcription}</div>
            </div>
          )}
          
          {audioSample.notes && (
            <div className="info-row">
              <div className="info-label">Notes:</div>
              <div className="info-value notes">{audioSample.notes}</div>
            </div>
          )}
          
          {audioSample.adminFeedback && (
            <div className="info-row">
              <div className="info-label">Admin Feedback:</div>
              <div className="info-value admin-feedback">{audioSample.adminFeedback}</div>
            </div>
          )}
        </div>

        <div className="voting-section">
          <div className="votes-display">
            <div className="vote-count upvotes">
              <span className="vote-icon">👍</span>
              <span className="vote-number">{audioSample.votes.upvotes}</span>
            </div>
            <div className="vote-count downvotes">
              <span className="vote-icon">👎</span>
              <span className="vote-number">{audioSample.votes.downvotes}</span>
            </div>
          </div>
          
          {audioSample.status === 'approved' && (
            <div className="vote-buttons">
              <button 
                onClick={() => handleVote('upvote')} 
                disabled={submitting}
                className="vote-button upvote"
              >
                Upvote
              </button>
              <button 
                onClick={() => handleVote('downvote')} 
                disabled={submitting}
                className="vote-button downvote"
              >
                Downvote
              </button>
            </div>
          )}
        </div>

        {/* Admin Controls - Only visible to admins */}
        {currentUser?.role === 'admin' && (
          <div className="admin-controls">
            <h3>Admin Controls</h3>
            
            <div className="feedback-input">
              <label htmlFor="feedback">Feedback (optional):</label>
              <textarea
                id="feedback"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="Provide feedback to the contributor"
                rows="3"
              ></textarea>
            </div>
            
            <div className="status-buttons">
              <button
                onClick={() => handleStatusUpdate('approved')}
                disabled={submitting || audioSample.status === 'approved'}
                className="status-button approve"
              >
                Approve
              </button>
              <button
                onClick={() => handleStatusUpdate('rejected')}
                disabled={submitting || audioSample.status === 'rejected'}
                className="status-button reject"
              >
                Reject
              </button>
              <button
                onClick={() => handleStatusUpdate('in_game')}
                disabled={submitting || audioSample.status === 'in_game'}
                className="status-button in-game"
              >
                Add to Game
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AudioSampleDetails;
