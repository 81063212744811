import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import './AudioSamples.css';
import { Link } from 'react-router-dom';

const AudioSampleSubmission = () => {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({
    audioUrl: '',
    language: '',
    country: '',
    dialect: '',
    transcription: '',
    notes: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validateVocarooUrl = (url) => {
    if (!url) return false;
    
    try {
      const urlObj = new URL(url);
      return (urlObj.hostname.includes('vocaroo.com') || urlObj.hostname.includes('voca.ro')) && urlObj.pathname !== '/';
    } catch (error) {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    
    // Validate required fields
    if (!formData.audioUrl || !formData.language || !formData.country) {
      setError('Audio URL, language, and country are required fields');
      return;
    }

    // Validate Vocaroo URL
    if (!validateVocarooUrl(formData.audioUrl)) {
      setError('Please enter a valid Vocaroo URL (e.g., https://vocaroo.com/abc123 or https://voca.ro/abc123)');
      return;
    }

    setLoading(true);

    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/audio-samples`, 
        formData,
        {
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.data.success) {
        setSuccess('Your audio sample has been submitted successfully and is pending review.');
        // Reset form
        setFormData({
          audioUrl: '',
          language: '',
          country: '',
          dialect: '',
          transcription: '',
          notes: ''
        });
      } else {
        setError(response.data.message || 'Failed to submit audio sample');
      }
    } catch (error) {
      console.error('Error submitting audio sample:', error);
      setError(
        error.response?.data?.message || 
        'An error occurred while submitting your audio sample. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  if (!currentUser) {
    return (
      <div className="audio-sample-submission">
        <div className="submission-container">
        {/* Logo and Title START */}
        <div className="flex flex-col items-center mb-2">
          <Link to="/" className="hover:opacity-100 transition-opacity">
            <img src="/images/logo5.png" alt="LangGuesser Logo" className="h-32 w-32" />
          </Link>
          <div className="text-center">
            <Link to="/" className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-200 hover:from-blue-400 hover:to-green-300 transition-colors">
              LangGuesser's
            </Link>
          </div>
        </div>
        {/* Logo and Title END */}
          <h2>Submit Audio Sample</h2>
          <p className="text-center text-red-500">
            You must be logged in to submit audio samples.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="audio-sample-submission">
      <div className="submission-container">
      <div className="flex justify-end">
          <button className="back-button" onClick={() => window.history.back()}>
            &larr; Back
          </button>
      </div>
        {/* Logo and Title START */}
          <div className="flex flex-col items-center mb-2">
          <Link to="/" className="hover:opacity-100 transition-opacity">
            <img src="/images/logo5.png" alt="LangGuesser Logo" className="h-32 w-32" />
          </Link>
          <div className="text-center">
            <Link to="/" className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-200 hover:from-blue-400 hover:to-green-300 transition-colors">
              LangGuesser's
            </Link>
          </div>
        </div>
        {/* Logo and Title END */}
        <h2 className="text-3xl font-bold text-center mb-8 text-white">Submit Audio Sample</h2>
        
        <div className="tutorial-section">
          <h3 className="text-white text-xl font-bold mb-2">How to Submit an Audio Sample</h3>
          <ol className="tutorial-steps text-gray-100">
            <li>
              <strong>Record your voice:</strong> Go to <a href="https://vocaroo.com" target="_blank" rel="noopener noreferrer" className="vocaroo-link">vocaroo.com</a> and record yourself speaking in your native language or a language you're fluent in.
            </li>
            <li>
              <strong>Save your recording:</strong> After recording, click "Save & Share" and copy the URL from your browser's address bar.
            </li>
            <li>
              <strong>Submit the details:</strong> Paste the URL below along with information about the language and country.
            </li>
            <li>
              <strong>Wait for approval:</strong> Our team will review your submission before making it available for community voting.
            </li>
          </ol>
        </div>

        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}

        <form onSubmit={handleSubmit} className="submission-form">
          <div className="form-group">
            <label htmlFor="audioUrl">Vocaroo URL *</label>
            <input
              type="url"
              id="audioUrl"
              name="audioUrl"
              value={formData.audioUrl}
              onChange={handleChange}
              placeholder="https://vocaroo.com/your-recording-id"
              required
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label htmlFor="language">Language *</label>
            <input
              type="text"
              id="language"
              name="language"
              value={formData.language}
              onChange={handleChange}
              placeholder="e.g., French, Spanish, Mandarin"
              required
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label htmlFor="country">Country of Origin *</label>
            <input
              type="text"
              id="country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              placeholder="e.g., France, Mexico, China"
              required
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label htmlFor="dialect">Accent/Dialect (Optional)</label>
            <input
              type="text"
              id="dialect"
              name="dialect"
              value={formData.dialect}
              onChange={handleChange}
              placeholder="e.g., Québécois, Andalusian, Wu Chinese"
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label htmlFor="transcription">Transcription (Optional)</label>
            <textarea
              id="transcription"
              name="transcription"
              value={formData.transcription}
              onChange={handleChange}
              placeholder="Write what you said in the recording"
              className="form-textarea"
              rows="3"
            />
          </div>

          <div className="form-group">
            <label htmlFor="notes">Cultural Context/Notes (Optional)</label>
            <textarea
              id="notes"
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              placeholder="Any additional information about the language, dialect, or cultural context"
              className="form-textarea"
              rows="3"
            />
          </div>

          <div className="form-actions">
            <button 
              type="submit" 
              className="submit-button"
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Submit Audio Sample'}
            </button>
          </div>
        </form>

        <div className="submission-guidelines">
          <h4 className="text-white text-xl font-bold mb-2">Submission Guidelines:</h4>
          <ul className="text-gray-100">
            <li>Speak clearly and naturally in your native language or a language you're fluent in.</li>
            <li>Recordings should be between 10-30 seconds for optimal quality.</li>
            <li>Avoid background noise and interruptions.</li>
            <li>Do not include personal identifying information.</li>
            <li>Inappropriate content will be rejected.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AudioSampleSubmission;
