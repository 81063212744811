import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const ResetPassword = ({ logo }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tokenValid, setTokenValid] = useState(true);
  const { token } = useParams();
  const { resetPassword } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Verify token format - basic validation
    if (!token || token.length < 10) {
      setTokenValid(false);
      setError('Invalid password reset link. Please request a new one.');
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate passwords
    if (password !== confirmPassword) {
      return setError('Passwords do not match');
    }
    
    if (password.length < 6) {
      return setError('Password must be at least 6 characters long');
    }
    
    setLoading(true);
    setError('');
    
    try {
      const result = await resetPassword(token, password);
      
      if (result.success) {
        setSuccess(true);
        // Redirect to login after 3 seconds
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } else {
        setError(result.error || 'Failed to reset password');
      }
    } catch (error) {
      setError('Error resetting password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="w-full max-w-md p-4">
        <div className="rounded-lg shadow-lg bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 text-white p-6">
          <div className="flex flex-col items-center mb-2">
            <Link to="/" className="hover:opacity-80 transition-opacity">
              <img src={logo} alt="LangGuesser Logo" className="h-32 w-32" />
            </Link>
          </div>
          <h2 className="text-2xl font-bold text-center mb-2">LangGuesser</h2>
          <h2 className="text-3xl font-bold text-center mb-6">Reset Password</h2>
          
          {!tokenValid ? (
            <div className="bg-red-500/20 border border-red-500/30 text-red-200 p-4 rounded-md mb-4">
              <p>{error}</p>
              <div className="mt-4 text-center">
                <Link to="/login" className="text-white hover:text-white/80 underline">
                  Return to login
                </Link>
              </div>
            </div>
          ) : success ? (
            <div className="bg-green-500/20 border border-green-500/30 text-green-200 p-4 rounded-md mb-4 text-center">
              <p className="mb-2">Your password has been successfully reset!</p>
              <p>Redirecting to login page...</p>
            </div>
          ) : (
            <>
              {error && (
                <div className="bg-red-500/20 border border-red-500/30 text-red-200 p-3 rounded-md mb-4">
                  {error}
                </div>
              )}
              
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label className="block text-white text-sm font-medium mb-2">
                    New Password
                  </label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-3 py-2 bg-white/10 border border-white/20 rounded-md text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/30"
                    required
                  />
                </div>
                
                <div>
                  <label className="block text-white text-sm font-medium mb-2">
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full px-3 py-2 bg-white/10 border border-white/20 rounded-md text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/30"
                    required
                  />
                </div>
                
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? 'Resetting...' : 'Reset Password'}
                </button>
              </form>
              
              <div className="mt-4 text-center">
                <Link to="/login" className="text-white hover:text-white/80 text-sm">
                  Return to login
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
