import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './AudioSamples.css';

const AdminAudioSamples = () => {
  const { currentUser } = useAuth();
  const [audioSamples, setAudioSamples] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [filters, setFilters] = useState({
    status: 'pending',
    language: '',
    country: '',
    sortBy: 'date',
    order: 'desc'
  });
  const [adminNotes, setAdminNotes] = useState({});
  const [updateStatus, setUpdateStatus] = useState({});
  const [stats, setStats] = useState({
    pending: 0,
    approved: 0,
    rejected: 0,
    inGame: 0
  });

  useEffect(() => {
    if (currentUser && currentUser.role === 'admin') {
      fetchAudioSamples();
      fetchStats();
    }
  }, [currentUser, filters]);

  const fetchStats = async () => {
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      if (!token) return;

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/audio-samples/admin-stats`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.success) {
        setStats({
          pending: response.data.data.pending || 0,
          approved: response.data.data.approved || 0,
          rejected: response.data.data.rejected || 0,
          inGame: response.data.data.inGame || 0
        });
      }
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  };

  const fetchAudioSamples = async () => {
    try {
      setLoading(true);
      
      // Build query string from filters
      const queryParams = new URLSearchParams();
      
      if (filters.status) queryParams.append('status', filters.status);
      if (filters.language) queryParams.append('language', filters.language);
      if (filters.country) queryParams.append('country', filters.country);
      if (filters.sortBy) queryParams.append('sortBy', filters.sortBy);
      if (filters.order) queryParams.append('order', filters.order);
      
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/audio-samples?${queryParams.toString()}`,
        {
          headers: { 
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.data.success) {
        setAudioSamples(response.data.data);
      } else {
        setError('Failed to fetch audio samples');
      }
    } catch (error) {
      console.error('Error fetching audio samples:', error);
      setError('An error occurred while fetching audio samples');
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleAdminNotesChange = (sampleId, notes) => {
    setAdminNotes(prevNotes => ({
      ...prevNotes,
      [sampleId]: notes
    }));
  };

  const updateSampleStatus = async (sampleId, newStatus) => {
    if (!currentUser || currentUser.role !== 'admin') {
      setError('Admin privileges required');
      return;
    }

    try {
      setUpdateStatus(prev => ({
        ...prev,
        [sampleId]: 'updating'
      }));

      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/audio-samples/${sampleId}/status`,
        { 
          status: newStatus,
          adminNotes: adminNotes[sampleId] || ''
        },
        {
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.data.success) {
        // Update the sample status in the UI
        setAudioSamples(prevSamples => 
          prevSamples.map(sample => {
            if (sample._id === sampleId) {
              return {
                ...sample,
                status: newStatus,
                adminNotes: adminNotes[sampleId] || sample.adminNotes
              };
            }
            return sample;
          })
        );

        setUpdateStatus(prev => ({
          ...prev,
          [sampleId]: 'success'
        }));

        // Update stats after status change
        fetchStats();

        // Clear success status after 2 seconds
        setTimeout(() => {
          setUpdateStatus(prev => {
            const newStatus = {...prev};
            delete newStatus[sampleId];
            return newStatus;
          });
          
          // Refresh the list if we're filtering by status
          if (filters.status) {
            fetchAudioSamples();
          }
        }, 2000);
      } else {
        setUpdateStatus(prev => ({
          ...prev,
          [sampleId]: 'error'
        }));
        setError(response.data.message || 'Failed to update status');
      }
    } catch (error) {
      console.error('Error updating audio sample status:', error);
      setUpdateStatus(prev => ({
        ...prev,
        [sampleId]: 'error'
      }));
      setError(
        error.response?.data?.message || 
        'An error occurred while updating the status. Please try again.'
      );
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  if (!currentUser || currentUser.role !== 'admin') {
    return (
      <div className="admin-audio-samples">
        <div className="admin-container">
        {/* Logo and Title START */}
        <div className="flex flex-col items-center mb-2">
          <Link to="/" className="hover:opacity-100 transition-opacity">
            <img src="images/logo5.png" alt="LangGuesser Logo" className="h-32 w-32" />
          </Link>
          <div className="text-center">
            <Link to="/" className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-200 hover:from-blue-400 hover:to-green-300 transition-colors">
              LangGuesser's
            </Link>
          </div>
        </div>
        {/* Logo and Title END */}
          <h2>Admin Audio Samples Management</h2>
          <p className="text-center text-red-500">
            You must be an admin to access this page.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="admin-audio-samples">
      <div className="admin-container">
        <div className="flex justify-end">
          <Link to="/admin">
            <button className="back-button">
            &larr; Back
            </button>
          </Link>
        </div>
        {/* Logo and Title START */}
        <div className="flex flex-col items-center mb-2">
          <Link to="/" className="hover:opacity-100 transition-opacity">
            <img src="/images/logo5.png" alt="LangGuesser Logo" className="h-32 w-32" />
          </Link>
          <div className="text-center">
            <Link to="/" className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-200 hover:from-blue-400 hover:to-green-300 transition-colors">
              LangGuesser's
            </Link>
          </div>
        </div>
        {/* Logo and Title END */}
        <div className="flex items-center justify-between mb-8">
          <h2 className="text-3xl font-bold text-center text-white">Admin Audio Samples Management</h2>
        </div>
        {/* Stats Overview */}
        <div className="stats-overview">
          <div className="stat-card pending">
            <div className="stat-number text-white">{stats.pending}</div>
            <div className="stat-label">Pending</div>
            <button 
              className="stat-action" 
              onClick={() => setFilters({...filters, status: 'pending'})}
              disabled={filters.status === 'pending'}
            >
              View
            </button>
          </div>
          <div className="stat-card approved">
            <div className="stat-number text-white">{stats.approved}</div>
            <div className="stat-label">Approved</div>
            <button 
              className="stat-action" 
              onClick={() => setFilters({...filters, status: 'approved'})}
              disabled={filters.status === 'approved'}
            >
              View
            </button>
          </div>
          <div className="stat-card rejected">
            <div className="stat-number text-white">{stats.rejected}</div>
            <div className="stat-label">Rejected</div>
            <button 
              className="stat-action" 
              onClick={() => setFilters({...filters, status: 'rejected'})}
              disabled={filters.status === 'rejected'}
            >
              View
            </button>
          </div>
          <div className="stat-card in-game">
            <div className="stat-number text-white">{stats.inGame}</div>
            <div className="stat-label">In Game</div>
            <button 
              className="stat-action" 
              onClick={() => setFilters({...filters, status: 'in_game'})}
              disabled={filters.status === 'in_game'}
            >
              View
            </button>
          </div>
        </div>
        
        <div className="filter-section">
          <h3 className="text-2xl font-bold mb-2 text-white">Filter Samples</h3>
          <div className="filter-controls">
            <div className="filter-group text-white">
              <label htmlFor="status">Status</label>
              <select
                id="status"
                name="status"
                value={filters.status}
                onChange={handleFilterChange}
                className="filter-select"
              >
                <option value="pending">Pending Review</option>
                <option value="approved">Approved for Voting</option>
                <option value="rejected">Rejected</option>
                <option value="in_game">In Game</option>
              </select>
            </div>
            
            <div className="filter-group text-white">
              <label htmlFor="language">Language</label>
              <input
                type="text"
                id="language"
                name="language"
                value={filters.language}
                onChange={handleFilterChange}
                placeholder="Filter by language"
                className="filter-input"
              />
            </div>
            
            <div className="filter-group text-white">
              <label htmlFor="country">Country</label>
              <input
                type="text"
                id="country"
                name="country"
                value={filters.country}
                onChange={handleFilterChange}
                placeholder="Filter by country"
                className="filter-input"
              />
            </div>
            
            <div className="filter-group text-white">
              <label htmlFor="sortBy">Sort By</label>
              <select
                id="sortBy"
                name="sortBy"
                value={filters.sortBy}
                onChange={handleFilterChange}
                className="filter-select"
              >
                <option value="date">Date Submitted</option>
                <option value="votes">Popularity</option>
              </select>
            </div>
            
            <div className="filter-group text-white">
              <label htmlFor="order">Order</label>
              <select
                id="order"
                name="order"
                value={filters.order}
                onChange={handleFilterChange}
                className="filter-select"
              >
                <option value="desc">Descending</option>
                <option value="asc">Ascending</option>
              </select>
            </div>
          </div>
        </div>

        {error && <div className="error-message">{error}</div>}

        {loading ? (
          <div className="loading-message text-white">Loading audio samples...</div>
        ) : audioSamples.length === 0 ? (
          <div className="no-samples-message text-gray-300 italic">
            <p>No audio samples found matching your criteria.</p>
          </div>
        ) : (
          <div className="admin-samples-list">
            {audioSamples.map(sample => (
              <div key={sample._id} className="admin-sample-card">
                <div className="sample-header">
                  <Link to={`/audio-samples/${sample._id}`} className="sample-title-link mb-2">
                    <h3>{sample.language} ({sample.country})</h3>
                  </Link>
                  <span className={`status-badge ${sample.status}`}>
                    {sample.status.charAt(0).toUpperCase() + sample.status.slice(1).replace('_', ' ')}
                  </span>
                </div>
                
                <div className="sample-audio mb-2">
                  <iframe 
                    title={`Vocaroo audio player for ${sample._id}`}
                    src={sample.audioUrl} 
                    frameBorder="0" 
                    allow="autoplay"
                    className="vocaroo-player"
                  ></iframe>
                </div>
                
                <div className="sample-details">
                  {sample.dialect && (
                    <div className="detail-item">
                      <span className="detail-label">Dialect:</span>
                      <span className="detail-value">{sample.dialect}</span>
                    </div>
                  )}
                  
                  {sample.transcription && (
                    <div className="detail-item">
                      <span className="detail-label">Transcription:</span>
                      <span className="detail-value">{sample.transcription}</span>
                    </div>
                  )}
                  
                  {sample.notes && (
                    <div className="detail-item">
                      <span className="detail-label">Notes:</span>
                      <span className="detail-value">{sample.notes}</span>
                    </div>
                  )}
                  
                  <div className="detail-item">
                    <span className="detail-label">Submitted by:</span>
                    <span className="detail-value">
                      {sample.userId ? sample.userId.nickname : 'Anonymous'}
                    </span>
                  </div>
                  
                  <div className="detail-item">
                    <span className="detail-label">Date:</span>
                    <span className="detail-value">{formatDate(sample.submittedAt)}</span>
                  </div>
                  
                  {sample.status === 'approved' && (
                    <div className="votes-container">
                      <div className="votes">
                        <span className="upvotes">👍 {sample.votes.upvotes}</span>
                        <span className="downvotes">👎 {sample.votes.downvotes}</span>
                      </div>
                    </div>
                  )}
                </div>
                
                <div className="admin-actions">
                  <div className="admin-notes-section">
                    <label htmlFor={`adminNotes-${sample._id}`}>Admin Notes:</label>
                    <textarea
                      id={`adminNotes-${sample._id}`}
                      value={adminNotes[sample._id] || sample.adminNotes || ''}
                      onChange={(e) => handleAdminNotesChange(sample._id, e.target.value)}
                      placeholder="Add notes (will be visible to the submitter)"
                      className="admin-notes-input"
                      rows="2"
                    />
                  </div>
                  
                  <div className="status-buttons">
                    {sample.status !== 'approved' && (
                      <button
                        className="status-button approve"
                        onClick={() => updateSampleStatus(sample._id, 'approved')}
                        disabled={updateStatus[sample._id] === 'updating'}
                      >
                        {updateStatus[sample._id] === 'updating' ? 'Updating...' : 'Approve for Voting'}
                      </button>
                    )}
                    
                    {sample.status !== 'rejected' && (
                      <button
                        className="status-button reject"
                        onClick={() => updateSampleStatus(sample._id, 'rejected')}
                        disabled={updateStatus[sample._id] === 'updating'}
                      >
                        {updateStatus[sample._id] === 'updating' ? 'Updating...' : 'Reject'}
                      </button>
                    )}
                    
                    {sample.status !== 'in_game' && (
                      <button
                        className="status-button in-game"
                        onClick={() => updateSampleStatus(sample._id, 'in_game')}
                        disabled={updateStatus[sample._id] === 'updating'}
                      >
                        {updateStatus[sample._id] === 'updating' ? 'Updating...' : 'Mark as In Game'}
                      </button>
                    )}
                  </div>
                  
                  {updateStatus[sample._id] === 'success' && (
                    <div className="update-success">Status updated successfully!</div>
                  )}
                  
                  {updateStatus[sample._id] === 'error' && (
                    <div className="update-error">Error updating status</div>
                  )}
                  
                  <Link to={`/audio-samples/${sample._id}`} className="view-details-link">
                    View Full Details
                  </Link>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminAudioSamples;
