import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <div className="min-h-screen flex flex-col items-center p-4">
      {/* Header Section */}
      <div className="text-center mb-5 items-center p-4 bg-black/50 backdrop-blur-sm rounded-xl border border-white/10">
        <Link to="/" className="text-4xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-200 hover:from-blue-400 hover:to-green-300 transition-colors">
          LangGuesser
        </Link>
        <h1 className="text-2xl font-bold mb-1 mt-4 text-white">Terms of Service</h1>
        <p className="text-md mb-2 text-white italic">Last updated: February 18, 2025</p>
      </div>

      {/* Content Section */}
      <div className="w-full max-w-3xl bg-black/70 backdrop-blur-sm rounded-lg p-6 shadow-lg border border-white/10">
        <div className="space-y-6 text-white">
          <section className="bg-black/30 p-4 rounded-lg border border-white/10">
            <h2 className="text-xl font-semibold mb-2">1. Acceptance</h2>
            <p className="text-gray-300">
              By using LangGuesser, you agree to these terms. If you disagree,
              please do not use the service.
            </p>
          </section>

          <section className="bg-black/30 p-4 rounded-lg border border-white/10">
            <h2 className="text-xl font-semibold mb-2">2. Account Rules</h2>
            <ul className="list-disc pl-6 space-y-1 text-gray-300">
              <li>Keep your account secure</li>
              <li>Provide accurate information</li>
              <li>Don't share credentials</li>
              <li>Report unauthorized access</li>
            </ul>
          </section>

          <section className="bg-black/30 p-4 rounded-lg border border-white/10">
            <h2 className="text-xl font-semibold mb-2">3. Prohibited Actions</h2>
            <ul className="list-disc pl-6 space-y-1 text-gray-300">
              <li>Using the service illegally</li>
              <li>Creating multiple accounts</li>
              <li>Manipulating scores</li>
              <li>Disrupting the service</li>
            </ul>
          </section>

          <section className="bg-black/30 p-4 rounded-lg border border-white/10">
            <h2 className="text-xl font-semibold mb-2">4. Termination</h2>
            <p className="text-gray-300">
              We may suspend or terminate accounts that violate these terms,
              without prior notice.
            </p>
          </section>

          <section className="bg-black/30 p-4 rounded-lg border border-white/10">
            <h2 className="text-xl font-semibold mb-2">5. Contact</h2>
            <p className="text-gray-300">
              Questions? Email us at:{' '}
              <a href="mailto:terms@langguesser.com" className="text-green-300 hover:text-green-500">
                terms@langguesser.com
              </a>
            </p>
          </section>
        </div>
      </div>

      {/* Footer */}
      <div className="mt-6">
        <Link to="/" className="font-bold text-green-300 hover:text-green-500">
          Go back
        </Link>
      </div>
    </div>
  );
};

export default TermsOfService;
