import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('token') || sessionStorage.getItem('token');
    const storedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
    if (storedToken && storedUser) {
      setToken(storedToken);
      setCurrentUser(JSON.parse(storedUser));
    }
    setLoading(false);
  }, []);

  const signup = async (email, password, newsletter) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/register`, {
      email,
      password,
      newsletter
    });
    
    // Don't set token yet - wait for email verification
    return response;
  };

  const verifyEmail = async (token) => {
    try {
      console.log('Making verification request...');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/verify-email/${token}`);
      console.log('Raw verification response:', response.data);
      
      if (!response.data.success) {
        console.log('Verification failed:', response.data.error);
        throw new Error(response.data.error || 'Verification failed');
      }
      
      console.log('Verification successful, updating state...');
      const { token: newToken, user } = response.data;
      localStorage.setItem('token', newToken);
      localStorage.setItem('user', JSON.stringify(user));
      setCurrentUser(user);
      setToken(newToken);
      console.log('State updated successfully');
      
      return response;
    } catch (error) {
      console.error('Verification error in context:', error);
      // Clear any existing token and user state on error
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      setCurrentUser(null);
      setToken(null);
      throw error;
    }
  };

  const resendVerification = async (email) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/resend-verification`, { email });
  };

  const forgotPassword = async (email) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/forgot-password`, { email });
      return {
        success: true,
        message: response.data.message || 'Password reset email sent'
      };
    } catch (error) {
      console.error('Forgot password error:', error);
      return {
        success: false,
        error: error.response?.data?.error || 'Error sending password reset email'
      };
    }
  };

  const resetPassword = async (token, password) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/reset-password/${token}`, { password });
      return {
        success: true,
        message: response.data.message || 'Password reset successful'
      };
    } catch (error) {
      console.error('Reset password error:', error);
      return {
        success: false,
        error: error.response?.data?.error || 'Error resetting password'
      };
    }
  };

  const changePassword = async (currentPassword, newPassword) => {
    try {
      const storedToken = localStorage.getItem('token') || sessionStorage.getItem('token');
      if (!storedToken) throw new Error('Not authenticated');

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/change-password`,
        { currentPassword, newPassword },
        {
          headers: { Authorization: `Bearer ${storedToken}` }
        }
      );
      
      return {
        success: true,
        message: response.data.message || 'Password changed successfully'
      };
    } catch (error) {
      console.error('Change password error:', error);
      return {
        success: false,
        error: error.response?.data?.error || 'Error changing password'
      };
    }
  };

  const login = async (email, password, rememberMe = false) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
        email,
        password,
        rememberMe
      });

      if (!response.data.success) {
        return {
          success: false,
          error: response.data.error || 'Login failed'
        };
      }

      const { token, user } = response.data.data;
      
      // If rememberMe is true, store in localStorage, otherwise in sessionStorage
      if (rememberMe) {
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
      } else {
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('user', JSON.stringify(user));
      }
      
      setCurrentUser(user);
      setToken(token);
      return { success: true };
    } catch (error) {
      console.error('Login error:', error);
      return {
        success: false,
        error: error.response?.data?.error || 'Error logging in'
      };
    }
  };

  const googleLogin = async (token) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/google`, { token });
    const { token: authToken, user } = response.data;
    localStorage.setItem('token', authToken);
    localStorage.setItem('user', JSON.stringify(user));
    setCurrentUser(user);
    setToken(authToken);
    return response;
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    setCurrentUser(null);
    setToken(null);
  };

  const updateEmailPreferences = async (preferences) => {
    const storedToken = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (!storedToken) throw new Error('Not authenticated');

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/auth/email-preferences`,
      preferences,
      {
        headers: { Authorization: `Bearer ${storedToken}` }
      }
    );

    return response;
  };

  const value = {
    currentUser,
    token,
    signup,
    login,
    logout,
    googleLogin,
    verifyEmail,
    resendVerification,
    updateEmailPreferences,
    forgotPassword,
    resetPassword,
    changePassword,
    user: currentUser ? { 
      ...currentUser, 
      token 
    } : null
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
