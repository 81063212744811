import React, { useState, useEffect } from 'react';
import './MatchFoundPopup.css';

const MatchFoundPopup = ({ matchData, onAccept, onDecline }) => {
  const [timeLeft, setTimeLeft] = useState(10);
  const [hasResponded, setHasResponded] = useState(false);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    if (timeLeft <= 0) {
      onDecline();
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft(prev => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, onDecline]);

  const handleAccept = () => {
    setHasResponded(true);
    setResponse('accepted');
    onAccept();
  };

  const handleDecline = () => {
    setHasResponded(true);
    setResponse('declined');
    onDecline();
  };

  // Get opponent name - server sends it as a direct string in matchData.opponent
  const opponentName = matchData?.opponent || 'Unknown Player';

  return (
    <div className="match-popup-overlay">
      <div className="match-popup">
        <div className="match-popup-content">
          <h2>Match Found!</h2>
          <p>Opponent: {opponentName}</p>
          <div className="match-timer">
            <div className="timer-circle">
              <svg viewBox="0 0 36 36">
                <path
                  d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                  fill="none"
                  stroke="var(--primary-color, #4299e1)"
                  strokeWidth="2"
                  strokeDasharray={`${(timeLeft / 10) * 100}, 100`}
                />
              </svg>
              <span>{timeLeft}</span>
            </div>
          </div>
          {/* Separate div for buttons with higher z-index */}
          <div className="match-buttons">
            <button
              onClick={handleAccept}
              disabled={hasResponded}
              className={`accept-button
                ${hasResponded
                  ? response === 'accepted'
                    ? 'bg-green-600 text-white cursor-not-allowed'
                    : 'bg-gray-600 text-gray-400 cursor-not-allowed'
                  : 'bg-green-500 hover:bg-green-600 text-white'
                }`}
            >
              {hasResponded && response === 'accepted' ? 'Accepted!' : 'Accept'}
            </button>
            <button
              onClick={handleDecline}
              disabled={hasResponded}
              className={`decline-button
                ${hasResponded
                  ? response === 'declined'
                    ? 'bg-red-600 text-white cursor-not-allowed'
                    : 'bg-gray-600 text-gray-400 cursor-not-allowed'
                  : 'bg-red-500 hover:bg-red-600 text-white'
                }`}
            >
              {hasResponded && response === 'declined' ? 'Declined!' : 'Decline'}
            </button>
          </div>
          {hasResponded && response === 'accepted' && (
            <p className="mt-4 text-green-400 animate-pulse">
              Waiting for other player...
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MatchFoundPopup;
