import React, { useState, useEffect } from 'react';
import { useNavigate, Link, Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import Footer from './Footer';
import { Modal } from 'antd';

export default function Dashboard() {
  const { currentUser, logout, changePassword } = useAuth();
  const navigate = useNavigate();
  const [showEditNickname, setShowEditNickname] = useState(false);
  const [nickname, setNickname] = useState(currentUser?.nickname || '');
  const [email, setEmail] = useState(currentUser?.email || '');
  const [createdAt, setCreatedAt] = useState(currentUser?.createdAt ? new Date(currentUser.createdAt) : null);
  const [isEmailVerified, setIsEmailVerified] = useState(currentUser?.isEmailVerified || false);
  const [preferences, setPreferences] = useState(currentUser?.preferences || {});
  const [error, setError] = useState('');
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [gameStats, setGameStats] = useState({
    soloGames: 0,
    easyGames: 0,
    multiplayerGames: 0,
    averageScore: 0,
    highestScore: 0,
    recentGames: []
  });
  const [isGameModeModalVisible, setIsGameModeModalVisible] = useState(false);
  const [isLeaderboardModalVisible, setIsLeaderboardModalVisible] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordUpdateSuccess, setPasswordUpdateSuccess] = useState(false);
  const [passwordUpdateError, setPasswordUpdateError] = useState('');
  const [passwordUpdateLoading, setPasswordUpdateLoading] = useState(false);

  useEffect(() => {
    loadUserProfile();
  }, []);

  const loadUserProfile = async () => {
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/profile`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const { data } = response.data;
      setNickname(data.nickname);
      setEmail(data.email);
      setCreatedAt(new Date(data.createdAt));
      setIsEmailVerified(data.isEmailVerified);
      setPreferences(data.preferences);
      setGameStats(data.gameStats || {
        soloGames: 0,
        easyGames: 0,
        multiplayerGames: 0,
        averageScore: 0,
        highestScore: 0,
        recentGames: []
      });
    } catch (err) {
      console.error('Error loading user profile:', err);
      setError('Failed to load user profile. Please try again.');
    }
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }

      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/auth/profile`,
        { nickname },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setShowEditNickname(false);
      setUpdateSuccess(true);
      setTimeout(() => setUpdateSuccess(false), 3000);
    } catch (err) {
      console.error('Error updating profile:', err);
      setError('Failed to update profile. Please try again.');
    }
  };

  const handleUpdateEmailPreferences = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }

      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/auth/email-preferences`,
        { 
          newsletter: preferences.newsletter,
          gameUpdates: preferences.newsletter,
          weeklyStats: preferences.newsletter
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setUpdateSuccess(true);
      setTimeout(() => setUpdateSuccess(false), 3000);
    } catch (err) {
      console.error('Error updating preferences:', err);
      setError('Failed to update preferences. Please try again.');
    }
  };
  
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch {
      setError('Failed to log out');
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    
    // Reset states
    setPasswordUpdateSuccess(false);
    setPasswordUpdateError('');
    
    // Validate passwords
    if (newPassword !== confirmPassword) {
      setPasswordUpdateError('New passwords do not match');
      return;
    }
    
    if (newPassword.length < 6) {
      setPasswordUpdateError('New password must be at least 6 characters long');
      return;
    }
    
    setPasswordUpdateLoading(true);
    
    try {
      const result = await changePassword(currentPassword, newPassword);
      
      if (result.success) {
        setPasswordUpdateSuccess(true);
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        
        // Hide success message after 3 seconds
        setTimeout(() => {
          setPasswordUpdateSuccess(false);
        }, 3000);
      } else {
        setPasswordUpdateError(result.error || 'Failed to update password');
      }
    } catch (error) {
      setPasswordUpdateError('Error updating password');
    } finally {
      setPasswordUpdateLoading(false);
    }
  };

  const formatDate = (date) => {
    if (!date) return '';
    
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="min-h-screen bg-cover bg-center bg-no-repeat" style={{ backgroundImage: "url('/images/world-map-bg.jpg')" }}>
      {/* Header */}
      <header className="bg-gradient-to-r from-purple-600 via-blue-500 to-teal-400 shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <Link to="/" className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-200 hover:from-blue-400 hover:to-green-300 transition-colors">
              LangGuesser
            </Link>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {error && (
          <div className="bg-red-500 text-white p-4 rounded-lg mb-6">
            {error}
          </div>
        )}

        {updateSuccess && (
          <div className="bg-green-500 text-white p-4 rounded-lg mb-6">
            Profile updated successfully!
          </div>
        )}

        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Left Column - Profile and Stats */}
          <div className="lg:col-span-4 space-y-6">
            {/* User Profile Card */}
            <div className="rounded-lg shadow-lg bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 text-white p-6">
              <div className="flex flex-col items-center text-center">
                <div className="h-20 w-20 rounded-full bg-white/20 flex items-center justify-center text-3xl mb-4">
                  {nickname ? nickname[0].toUpperCase() : email[0].toUpperCase()}
                </div>
                
                {showEditNickname ? (
                  <form onSubmit={handleUpdateProfile} className="w-full space-y-2">
                    <input
                      type="text"
                      value={nickname}
                      onChange={(e) => setNickname(e.target.value)}
                      className="w-full px-3 py-2 border rounded-md text-gray-900"
                      placeholder="Enter new nickname"
                    />
                    <div className="flex space-x-2 justify-center">
                      <button
                        type="submit"
                        className="px-3 py-1 bg-green-500 text-white rounded-md hover:bg-green-600"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        onClick={() => setShowEditNickname(false)}
                        className="px-3 py-1 bg-gray-600 text-white rounded-md hover:bg-gray-700"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                ) : (
                  <>
                    <h2 className="text-2xl font-semibold mb-1">
                      {nickname || email}
                    </h2>
                    <button
                      onClick={() => setShowEditNickname(true)}
                      className="text-sm text-white/80 hover:text-white mb-4"
                    >
                      Edit nickname
                    </button>
                  </>
                )}
                
                <p className="text-white/80 text-sm">
                  Member since: {formatDate(createdAt)}
                </p>
                
                <div className="mt-4 flex items-center">
                  <span className={`h-3 w-3 rounded-full ${isEmailVerified ? 'bg-green-400' : 'bg-yellow-400'} mr-2`}></span>
                  <span className="text-sm">
                    {isEmailVerified ? 'Email verified' : 'Email not verified'}
                  </span>
                </div>
              </div>
            </div>

            {/* Stats Card */}
            <div className="rounded-lg shadow-lg bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 text-white p-6">
              <h2 className="text-lg font-semibold mb-4 text-center">Your Stats</h2>
              <div className="grid grid-cols-2 gap-4">
                <div className="text-center">
                  <p className="text-white/80">Classic Mode</p>
                  <p className="text-2xl font-semibold">
                    {gameStats.soloGames || 0}
                  </p>
                </div>
                <div className="text-center">
                  <p className="text-white/80">Easy Mode</p>
                  <p className="text-2xl font-semibold">
                    {gameStats.easyGames || 0}
                  </p>
                </div>
                <div className="text-center">
                  <p className="text-white/80">Multiplayer</p>
                  <p className="text-2xl font-semibold">
                    {gameStats.multiplayerGames || 0}
                  </p>
                </div>
                <div className="text-center">
                  <p className="text-white/80">Highest Score</p>
                  <p className="text-2xl font-semibold">
                    {gameStats.highestScore || 0}
                  </p>
                </div>
              </div>
            </div>

            {/* Email Preferences */}
            <div className="rounded-lg shadow-lg bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 text-white p-6">
              <h2 className="text-lg font-semibold mb-4 text-center">Email Preferences</h2>
              <form onSubmit={handleUpdateEmailPreferences} className="space-y-4">
                <div className="flex items-center space-x-2 justify-center">
                  <input
                    type="checkbox"
                    id="newsletter"
                    checked={preferences.newsletter || false}
                    onChange={(e) => setPreferences({ ...preferences, newsletter: e.target.checked })}
                    className="h-4 w-4"
                  />
                  <label htmlFor="newsletter" className="text-sm">
                    Subscribe to newsletter
                  </label>
                </div>
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="px-4 py-2 bg-white/10 hover:bg-white/20 rounded-md text-white transition"
                  >
                    Update Preferences
                  </button>
                </div>
              </form>
            </div>

            {/* Change Password */}
            <div className="rounded-lg shadow-lg bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 text-white p-6">
              <h2 className="text-lg font-semibold mb-4 text-center">Change Password</h2>
              {passwordUpdateSuccess && (
                <div className="bg-green-500/20 border border-green-500/30 text-green-200 p-3 rounded-md mb-4">
                  Password updated successfully!
                </div>
              )}
              {passwordUpdateError && (
                <div className="bg-red-500/20 border border-red-500/30 text-red-200 p-3 rounded-md mb-4">
                  {passwordUpdateError}
                </div>
              )}
              <form onSubmit={handleChangePassword} className="space-y-4">
                <div>
                  <label className="block text-white text-sm font-medium mb-2">
                    Current Password
                  </label>
                  <input
                    type="password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    className="w-full px-3 py-2 bg-white/10 border border-white/20 rounded-md text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/30"
                    required
                  />
                </div>
                <div>
                  <label className="block text-white text-sm font-medium mb-2">
                    New Password
                  </label>
                  <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="w-full px-3 py-2 bg-white/10 border border-white/20 rounded-md text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/30"
                    required
                  />
                </div>
                <div>
                  <label className="block text-white text-sm font-medium mb-2">
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full px-3 py-2 bg-white/10 border border-white/20 rounded-md text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/30"
                    required
                  />
                </div>
                <div className="flex justify-center">
                  <button
                    type="submit"
                    disabled={passwordUpdateLoading}
                    className="px-4 py-2 bg-white/10 hover:bg-white/20 rounded-md text-white transition disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {passwordUpdateLoading ? 'Updating...' : 'Update Password'}
                  </button>
                </div>
              </form>
            </div>

            {/* Audio Sample Submission Link */}
            <div className="rounded-lg shadow-lg bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 text-white p-6">
              <h2 className="text-lg font-semibold mb-4 text-center">Contribute Audio Samples</h2>
              <p className="text-white/80 text-sm mb-4 text-center">
                Help improve LangGuesser by contributing audio samples in your native language or a language you're fluent in.
              </p>
              <div className="flex justify-center">
                <Link
                  to="/submit-audio"
                  className="px-4 py-2 bg-white/10 hover:bg-white/20 rounded-md text-white text-center transition"
                >
                  Submit Audio Sample
                </Link>
              </div>
            </div>
          </div>

          {/* Right Column - Main Content */}
          <div className="lg:col-span-8 space-y-6">
            {/* Game History */}
            <div className="rounded-lg shadow-lg bg-white p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4 text-gray-800">Recent Games</h2>
              {gameStats.recentGames && gameStats.recentGames.length > 0 ? (
                <div className="space-y-4">
                  {gameStats.recentGames.map((game, index) => (
                    <div key={index} className="flex justify-between items-center border-b pb-2">
                      <div>
                        <p className="font-medium text-gray-800">{game.gameMode}</p>
                        <p className="text-sm text-gray-500">{formatDate(game.playedAt)}</p>
                      </div>
                      <div className="text-right">
                        <p className="font-semibold text-gray-800">{game.score} points</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500">No games played yet</p>
              )}
            </div>

            {/* Navigation Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {[
                {
                  title: 'Solo Play',
                  description: 'Test your language guessing skills',
                  icon: '🎮',
                  onClick: () => setIsGameModeModalVisible(true),
                  className: "cursor-pointer"
                },
                {
                  title: 'Multiplayer',
                  description: 'Compete with other players',
                  icon: '👥',
                  path: '/multiplayer'
                },
                {
                  title: 'Leaderboard',
                  description: 'View top players',
                  icon: '🏆',
                  onClick: () => setIsLeaderboardModalVisible(true),
                  className: "cursor-pointer"
                },
                {
                  title: 'Your Audio Samples',
                  description: 'Manage your submitted samples',
                  icon: '🎙️',
                  path: '/user-audio-samples'
                },
                {
                  title: 'Community Samples',
                  description: 'Vote on samples for the game',
                  icon: '👂',
                  path: '/audio-voting'
                }
              ].map((item, index) => (
                item.onClick ? (
                  <div
                    key={index}
                    onClick={item.onClick}
                    className="rounded-lg shadow-lg bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 text-white p-6 hover:shadow-xl transition-shadow cursor-pointer"
                  >
                    <div className="flex items-center mb-2">
                      <span className="text-2xl mr-2">{item.icon}</span>
                      <h3 className="text-xl font-semibold">{item.title}</h3>
                    </div>
                    <p>{item.description}</p>
                  </div>
                ) : (
                  <Link
                    key={index}
                    to={item.path}
                    className="rounded-lg shadow-lg bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 text-white p-6 hover:shadow-xl transition-shadow"
                  >
                    <div className="flex items-center mb-2">
                      <span className="text-2xl mr-2">{item.icon}</span>
                      <h3 className="text-xl font-semibold">{item.title}</h3>
                    </div>
                    <p>{item.description}</p>
                  </Link>
                )
              ))}
            </div>
          </div>
        </div>
      </main>

      {/* Game Mode Selection Modal */}
      <Modal
        title={
          <div className="text-center font-bold text-xl bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-blue-500">
            Ready to guess?
          </div>
        }
        open={isGameModeModalVisible}
        onCancel={() => setIsGameModeModalVisible(false)}
        footer={null}
        centered
        className="game-mode-selection-modal"
      >
        <div className="flex flex-col space-y-6 p-4">
          <div 
            onClick={() => {
              setIsGameModeModalVisible(false);
              navigate('/easy-mode');
            }}
            className="cursor-pointer bg-gradient-to-r from-purple-100 to-blue-100 hover:from-purple-200 hover:to-blue-200 p-4 rounded-lg border border-purple-300 transition-all duration-300 transform hover:scale-[1.02] hover:shadow-md"
          >
            <div className="flex items-center space-x-3">
              <span className="text-2xl mb-4">🌍</span>
              <h3 className="text-lg font-bold text-purple-700">Easy Mode</h3>
            </div>
            <p className="text-gray-700">
              Guess any country that speaks the language you hear in the audio clip. Perfect for beginners!
            </p>
          </div>
          
          <div 
            onClick={() => {
              setIsGameModeModalVisible(false);
              navigate('/game');
            }}
            className="cursor-pointer bg-gradient-to-r from-blue-100 to-indigo-100 hover:from-blue-200 hover:to-indigo-200 p-4 rounded-lg border border-blue-300 transition-all duration-300 transform hover:scale-[1.02] hover:shadow-md"
          >
            <div className="flex items-center space-x-3">
              <span className="text-2xl mb-4">🎯</span>
              <h3 className="text-lg font-bold text-blue-700">Classic Mode</h3>
            </div>
            <p className="text-gray-700">
              Identify the exact country the accent is from. A true test of your language recognition skills!
            </p>
          </div>
        </div>
      </Modal>

      {/* Leaderboard Selection Modal */}
      <Modal
        title={
          <div className="text-center font-bold text-xl bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-blue-500">
            Choose a Leaderboard
          </div>
        }
        open={isLeaderboardModalVisible}
        onCancel={() => setIsLeaderboardModalVisible(false)}
        footer={null}
        centered
        className="leaderboard-selection-modal"
      >
        <div className="flex flex-col space-y-6 p-4">
          <div 
            onClick={() => {
              setIsLeaderboardModalVisible(false);
              navigate('/easy-leaderboard');
            }}
            className="cursor-pointer bg-gradient-to-r from-purple-100 to-blue-100 hover:from-purple-200 hover:to-blue-200 p-4 rounded-lg border border-purple-300 transition-all duration-300 transform hover:scale-[1.02] hover:shadow-md"
          >
            <div className="flex items-center space-x-3">
              <span className="text-2xl mb-4">🌍</span>
              <h3 className="text-lg font-bold text-purple-700">Easy Mode Leaderboard</h3>
            </div>
            <p className="text-gray-700">
              See the top players in Easy Mode where any country speaking the language is correct.
            </p>
          </div>
          
          <div 
            onClick={() => {
              setIsLeaderboardModalVisible(false);
              navigate('/leaderboard');
            }}
            className="cursor-pointer bg-gradient-to-r from-blue-100 to-indigo-100 hover:from-blue-200 hover:to-indigo-200 p-4 rounded-lg border border-blue-300 transition-all duration-300 transform hover:scale-[1.02] hover:shadow-md"
          >
            <div className="flex items-center space-x-3">
              <span className="text-2xl mb-4">🎯</span>
              <h3 className="text-lg font-bold text-blue-700">Classic Mode Leaderboard</h3>
            </div>
            <p className="text-gray-700">
              See the top players in Classic Mode where exact country identification is required.
            </p>
          </div>
        </div>
      </Modal>

      <Footer />
    </div>
  );
}
