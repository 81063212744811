import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './UpdatePopup.css';

const UpdatePopup = () => {
  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    // Check if the user has dismissed this popup before
    const hasSeenPopup = localStorage.getItem('multiplayer_update_seen');
    
    if (!hasSeenPopup) {
      // Show popup after a small delay
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 1500);
      
      return () => clearTimeout(timer);
    }
  }, []);
  
  const handleClose = () => {
    setIsVisible(false);
    // Remember that the user has seen this popup
    localStorage.setItem('multiplayer_update_seen', 'true');
  };
  
  if (!isVisible) return null;
  
  return (
    <div className="update-popup">
      <div className="update-popup-content">
        <button className="close-button" onClick={handleClose}>×</button>
        <h3>🎮 Multiplayer Mode is Back!</h3>
        <p>Challenge your friends and test your language skills against real players.</p>
        <Link to="/multiplayer" className="try-now-button" onClick={handleClose}>
          Try it now
        </Link>
      </div>
    </div>
  );
};

export default UpdatePopup;
