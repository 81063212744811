import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './AudioSamples.css';

const UserAudioSamples = () => {
  const { currentUser } = useAuth();
  const [audioSamples, setAudioSamples] = useState([]);
  const [stats, setStats] = useState({
    totalSubmitted: 0,
    pending: 0,
    approved: 0,
    rejected: 0,
    inGame: 0,
    totalUpvotes: 0,
    totalDownvotes: 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (currentUser) {
      fetchUserAudioSamples();
      fetchAudioSampleStats();
    }
  }, [currentUser]);

  const fetchUserAudioSamples = async () => {
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/audio-samples/user`,
        {
          headers: { 
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.data.success) {
        setAudioSamples(response.data.data);
      } else {
        setError('Failed to fetch your audio samples');
      }
    } catch (error) {
      console.error('Error fetching user audio samples:', error);
      setError('An error occurred while fetching your audio samples');
    } finally {
      setLoading(false);
    }
  };

  const fetchAudioSampleStats = async () => {
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/audio-samples/stats`,
        {
          headers: { 
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.data.success) {
        setStats(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching audio sample stats:', error);
    }
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'pending':
        return 'status-badge pending';
      case 'approved':
        return 'status-badge approved';
      case 'rejected':
        return 'status-badge rejected';
      case 'in_game':
        return 'status-badge in-game';
      default:
        return 'status-badge';
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'pending':
        return 'Pending Review';
      case 'approved':
        return 'Approved for Voting';
      case 'rejected':
        return 'Rejected';
      case 'in_game':
        return 'Added to Game';
      default:
        return status;
    }
  };

  if (!currentUser) {
    return (
      <div className="user-audio-samples">
        <div className="samples-container">
          <h2 className="text-3xl font-bold text-center mb-8 text-white">Your Audio Samples</h2>
          <p className="text-center text-red-500">
            You must be logged in to view your audio samples.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="user-audio-samples">
      <div className="samples-container">
      <div className="flex justify-end">
          <button className="back-button" onClick={() => window.history.back()}>
            &larr; Back
          </button>
      </div>
        {/* Logo and Title START */}
        <div className="flex flex-col items-center mb-2">
          <Link to="/" className="hover:opacity-100 transition-opacity">
            <img src="/images/logo5.png" alt="LangGuesser Logo" className="h-32 w-32" />
          </Link>
          <div className="text-center">
            <Link to="/" className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-200 hover:from-blue-400 hover:to-green-300 transition-colors">
              LangGuesser
            </Link>
          </div>
        </div>
        {/* Logo and Title END */}
        <h2 className="text-3xl font-bold text-center mb-8 text-white">Your Audio Samples</h2>
        
        <div className="stats-summary">
          <div className="stat-item">
            <span className="stat-label">Total Submitted</span>
            <span className="stat-value">{stats.totalSubmitted}</span>
          </div>
          <div className="stat-item">
            <span className="stat-label">Pending</span>
            <span className="stat-value">{stats.pending}</span>
          </div>
          <div className="stat-item">
            <span className="stat-label">Approved</span>
            <span className="stat-value">{stats.approved}</span>
          </div>
          <div className="stat-item">
            <span className="stat-label">In Game</span>
            <span className="stat-value">{stats.inGame}</span>
          </div>
          <div className="stat-item">
            <span className="stat-label">Total Upvotes</span>
            <span className="stat-value">{stats.totalUpvotes}</span>
          </div>
        </div>

        {error && <div className="error-message">{error}</div>}

        {loading ? (
          <div className="loading-message">Loading your audio samples...</div>
        ) : audioSamples.length === 0 ? (
          <div className="no-samples-message">
            <p>You haven't submitted any audio samples yet.</p>
            <Link to="/submit-audio" className="submit-link">Submit your first audio sample</Link>
          </div>
        ) : (
          <div className="audio-samples-list">
            {audioSamples.map(sample => (
              <div key={sample._id} className="audio-sample-card">
                <div className="sample-header">
                  <Link to={`/audio-samples/${sample._id}`} className="sample-title-link">
                    <h3>{sample.language} ({sample.country})</h3>
                  </Link>
                  <span className={getStatusBadgeClass(sample.status)}>
                    {getStatusText(sample.status)}
                  </span>
                </div>
                
                <div className="sample-audio">
                  <iframe 
                    title={`Vocaroo audio player for ${sample._id}`}
                    src={sample.audioUrl} 
                    frameBorder="0" 
                    allow="autoplay"
                    className="vocaroo-player"
                  ></iframe>
                </div>
                
                <div className="sample-details">
                  {sample.dialect && (
                    <div className="detail-item">
                      <span className="detail-label">Dialect:</span>
                      <span className="detail-value">{sample.dialect}</span>
                    </div>
                  )}
                  
                  {sample.transcription && (
                    <div className="detail-item">
                      <span className="detail-label">Transcription:</span>
                      <span className="detail-value">{sample.transcription}</span>
                    </div>
                  )}
                  
                  <div className="detail-item">
                    <span className="detail-label">Submitted:</span>
                    <span className="detail-value">{formatDate(sample.submittedAt)}</span>
                  </div>
                  
                  {sample.status === 'approved' && (
                    <div className="votes-container">
                      <div className="votes">
                        <span className="upvotes">👍 {sample.votes.upvotes}</span>
                        <span className="downvotes">👎 {sample.votes.downvotes}</span>
                      </div>
                    </div>
                  )}
                  
                  {sample.status === 'rejected' && sample.adminNotes && (
                    <div className="detail-item rejection-reason">
                      <span className="detail-label">Rejection Reason:</span>
                      <span className="detail-value">{sample.adminNotes}</span>
                    </div>
                  )}
                  
                  <Link to={`/audio-samples/${sample._id}`} className="view-details-link">
                    View Full Details
                  </Link>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserAudioSamples;
