import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';
import AdminNotifications from './audioSamples/AdminNotifications';

export default function AdminDashboard() {
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [audioSampleStats, setAudioSampleStats] = useState({
    pending: 0,
    approved: 0,
    rejected: 0,
    inGame: 0,
    total: 0
  });
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        if (!token) {
          console.error('No token found');
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/users`, {
          headers: { Authorization: `Bearer ${token}` }
        });
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    const fetchAudioSampleStats = async () => {
      try {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        if (!token) {
          console.error('No token found');
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/audio-samples/admin-stats`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (response.data.success) {
          setAudioSampleStats(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching audio sample stats:', error);
      }
    };

    fetchUsers();
    fetchAudioSampleStats();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    setError('');

    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      if (!token) throw new Error('Not authenticated');

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/newsletter`,
        { subject, content },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setMessage(response.data.data.message);
      setSubject('');
      setContent('');
    } catch (error) {
      console.error('Error sending newsletter:', error);
      if (error.response?.status === 403) {
        setError('You do not have admin privileges to send newsletters');
      } else if (error.response?.status === 401) {
        setError('Please log in again to continue');
      } else {
        setError(error.response?.data?.error || 'Failed to send newsletter');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center py-12">
      <div className="w-full max-w-4xl p-6">
        <div className="bg-white/10 backdrop-blur-md rounded-xl shadow-2xl p-8">
        <div className="flex justify-end">
          <Link to="/">
            <button className="back-button">
            &larr; Back
            </button>
          </Link>
        </div>
        {/* Logo and Title START */}
        <div className="flex flex-col items-center mb-2">
          <Link to="/" className="hover:opacity-100 transition-opacity">
            <img src="/images/logo5.png" alt="LangGuesser Logo" className="h-32 w-32" />
          </Link>
          <div className="text-center">
            <Link to="/" className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-200 hover:from-blue-400 hover:to-green-300 transition-colors">
              LangGuesser's
            </Link>
          </div>
        </div>
        {/* Logo and Title END */}
          <h2 className="text-3xl font-bold text-center mb-8 text-white">Admin Dashboard</h2>

          {error && (
            <div className="bg-red-500/20 backdrop-blur-sm border border-red-500/30 text-white rounded-lg p-4 mb-4">
              {error}
            </div>
          )}
          
          {message && (
            <div className="bg-green-500/20 backdrop-blur-sm border border-green-500/30 text-white rounded-lg p-4 mb-4">
              {message}
            </div>
          )}

          {/* Admin Notifications */}
          <div className="mb-8">
            <AdminNotifications />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
            {/* Audio Samples Management Section */}
            <div className="bg-white/5 backdrop-blur-sm rounded-lg p-6">
              <h3 className="text-xl font-semibold text-white mb-4">Audio Samples Management</h3>
              
              <div className="grid grid-cols-2 gap-4 mb-6">
                <div className="bg-white/10 rounded-lg p-4 text-center">
                  <span className="block text-3xl font-bold text-blue-400">{audioSampleStats.pending}</span>
                  <span className="text-white/70">Pending Review</span>
                </div>
                <div className="bg-white/10 rounded-lg p-4 text-center">
                  <span className="block text-3xl font-bold text-green-400">{audioSampleStats.approved}</span>
                  <span className="text-white/70">Approved</span>
                </div>
                <div className="bg-white/10 rounded-lg p-4 text-center">
                  <span className="block text-3xl font-bold text-red-400">{audioSampleStats.rejected}</span>
                  <span className="text-white/70">Rejected</span>
                </div>
                <div className="bg-white/10 rounded-lg p-4 text-center">
                  <span className="block text-3xl font-bold text-purple-400">{audioSampleStats.inGame}</span>
                  <span className="text-white/70">In Game</span>
                </div>
              </div>
              
              <Link 
                to="/admin/audio-samples" 
                className="block w-full py-2 px-4 text-center rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-indigo-500 to-purple-500 hover:from-indigo-600 hover:to-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Manage Audio Samples
              </Link>
              
              <div className="mt-4 text-white/70 text-sm">
                <p>Review and manage user-submitted audio samples. Approve quality submissions for community voting or add them directly to the game.</p>
              </div>
            </div>

            {/* Newsletter Section */}
            <div className="bg-white/5 backdrop-blur-sm rounded-lg p-6">
              <h3 className="text-xl font-semibold text-white mb-4">Send Newsletter</h3>

              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label className="block text-white text-sm font-medium mb-2">
                    Subject
                  </label>
                  <input
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    className="w-full px-3 py-2 bg-white/10 border border-white/20 rounded-md text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/30"
                    placeholder="Newsletter Subject"
                    required
                  />
                </div>

                <div>
                  <label className="block text-white text-sm font-medium mb-2">
                    Content (HTML)
                  </label>
                  <textarea
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    rows="6"
                    className="w-full px-3 py-2 bg-white/10 border border-white/20 rounded-md text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/30 font-mono"
                    placeholder="Newsletter Content (HTML supported)"
                    required
                  />
                </div>

                <button
                  type="submit"
                  disabled={loading}
                  className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? 'Sending...' : 'Send Newsletter'}
                </button>
              </form>
            </div>
          </div>

          {/* Admin Actions Section */}
          <div className="bg-white/5 backdrop-blur-sm rounded-lg p-6">
            <h3 className="text-xl font-semibold text-white mb-4">Quick Actions</h3>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              <Link 
                to="/admin/audio-samples?status=pending" 
                className="block py-3 px-4 text-center rounded-md shadow-sm text-sm font-medium text-white bg-orange-500/30 hover:bg-orange-500/50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              >
                Review Pending Samples
              </Link>
              
              <Link 
                to="/audio-voting" 
                className="block py-3 px-4 text-center rounded-md shadow-sm text-sm font-medium text-white bg-blue-500/30 hover:bg-blue-500/50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                View Community Voting
              </Link>
              
              <Link 
                to="/submit-audio" 
                className="block py-3 px-4 text-center rounded-md shadow-sm text-sm font-medium text-white bg-green-500/30 hover:bg-green-500/50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Submit New Audio
              </Link>
            </div>
          </div>

          <div className="mt-8 text-white/70 text-sm">
            <h4 className="font-semibold mb-2">Admin Tips:</h4>
            <ul className="list-disc list-inside space-y-1">
              <li>Review pending audio samples regularly to maintain content quality</li>
              <li>Use HTML tags to format newsletter content</li>
              <li>Test the newsletter by sending to a small group first</li>
              <li>Include a clear call-to-action in newsletters</li>
              <li>Monitor community voting to identify popular audio samples</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
