import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const EasyLeaderboard = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [countdown, setCountdown] = useState(10);
  const location = useLocation();
  const navigate = useNavigate();
  const message = location.state?.message;

  useEffect(() => {
    const fetchLeaderboardData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/easy-leaderboard`);
        setLeaderboardData(response.data);
      } catch (error) {
        console.error('Error fetching easy mode leaderboard data:', error);
      }
    };

    fetchLeaderboardData();
  }, []);

  useEffect(() => {
    if (message) {
      // Start countdown only if there's a message
      const intervalId = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            clearInterval(intervalId);
            // Instead of reloading, fetch new data and reset state
            fetchLeaderboardData();
            navigate('/easy-leaderboard', { replace: true });
            return 10;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [message, navigate]);

  const fetchLeaderboardData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/easy-leaderboard`);
      setLeaderboardData(response.data);
    } catch (error) {
      console.error('Error fetching easy mode leaderboard data:', error);
    }
  };

  const handlePlayAgain = () => {
    // Clear any intervals that might be running
    setCountdown(0);
    
    // Force clear the location state
    window.history.replaceState({}, document.title);
    
    // Reset all game-related states
    localStorage.removeItem('gameState');
    localStorage.removeItem('gameScore');
    
    // Navigate to easy mode game
    navigate('/easy-mode');
  };

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return 'N/A';
      }
      return new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit'
      }).format(date);
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'N/A';
    }
  };

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className="text-center mb-4">
        <Link to="/" className="text-4xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-200">
          LangGuesser's
        </Link>
      </div>
      
      {message && (
        <div className="alert flex justify-center items-center mb-4 text-white">
          <span>{message}</span>
          <span className="ml-2">Updating in {countdown}s...</span>
        </div>
      )}
      
      <h2 className="text-2xl font-bold mb-6 text-white">Easy Mode Leaderboard</h2>

      <div className="bg-black/30 backdrop-blur-lg p-4 rounded-lg shadow-lg w-full max-w-2xl">
        <table className="w-full">
          <thead className="text-white">
            <tr>
              <th className="px-4 py-2">Position</th>
              <th className="px-4 py-2">Nickname</th>
              <th className="px-4 py-2">Score</th>
              <th className="px-4 py-2">Date</th>
            </tr>
          </thead>
          <tbody className="text-white">
            {leaderboardData.map((entry, index) => (
              <tr key={index} className="border-t border-white/10">
                <td className="px-4 py-2 text-center">
                  {index === 0 ? '🥇' : index === 1 ? '🥈' : index === 2 ? '🥉' : index + 1}
                </td>
                <td className="px-4 py-2 text-center">{entry.nickname}</td>
                <td className="px-4 py-2 text-center">{entry.highestScore}</td>
                <td className="px-4 py-2 text-center">
                  {formatDate(entry.recentScores[0]?.timestamp)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-6 space-y-4">
        <button
          onClick={fetchLeaderboardData}
          className="px-4 py-2 bg-black/80 hover:bg-purple-500/60 text-white rounded transition-colors border border-white/20"
        >
          Refresh Leaderboard
        </button>
        
        <div className="flex justify-center space-x-4">
          <Link
            to="/easy-mode"
            onClick={handlePlayAgain}
            className="p-[3px] relative"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg" />
            <div className="px-6 py-2 bg-black rounded-[6px] relative group transition duration-200 text-white hover:bg-transparent">
              Play Again
            </div>
          </Link>
        </div>
        <div className="flex justify-center space-x-4">
          <Link
            to="/"
            className="p-[3px] relative"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-teal-500 rounded-lg" />
            <div className="px-6 py-2 bg-black rounded-[6px] relative group transition duration-200 text-white hover:bg-transparent">
              Home
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EasyLeaderboard;
