import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './AudioSamples.css';

const AudioSampleVoting = () => {
  const { currentUser } = useAuth();
  const [audioSamples, setAudioSamples] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [filters, setFilters] = useState({
    language: '',
    country: '',
    sortBy: 'date',
    order: 'desc'
  });
  const [votingStatus, setVotingStatus] = useState({});

  useEffect(() => {
    fetchAudioSamples();
  }, [filters]);

  const fetchAudioSamples = async () => {
    try {
      setLoading(true);
      
      // Build query string from filters
      const queryParams = new URLSearchParams();
      queryParams.append('status', 'approved');
      
      if (filters.language) queryParams.append('language', filters.language);
      if (filters.country) queryParams.append('country', filters.country);
      if (filters.sortBy) queryParams.append('sortBy', filters.sortBy);
      if (filters.order) queryParams.append('order', filters.order);
      
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/audio-samples?${queryParams.toString()}`
      );

      if (response.data.success) {
        setAudioSamples(response.data.data);
      } else {
        setError('Failed to fetch audio samples');
      }
    } catch (error) {
      console.error('Error fetching audio samples:', error);
      setError('An error occurred while fetching audio samples');
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleVote = async (sampleId, voteValue) => {
    try {
      setVotingStatus(prev => ({
        ...prev,
        [sampleId]: 'voting'
      }));

      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      
      // Configure request with or without auth token
      const config = token ? 
        {
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        } : 
        {
          headers: { 
            'Content-Type': 'application/json'
          }
        };
      
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/audio-samples/${sampleId}/vote`,
        { vote: voteValue },
        config
      );

      if (response.data.success) {
        // Update the vote count in the UI
        setAudioSamples(prevSamples => 
          prevSamples.map(sample => {
            if (sample._id === sampleId) {
              // Check if votes is directly in data or nested in data.votes
              const updatedVotes = response.data.data.votes || {
                upvotes: response.data.data.upvotes || sample.votes.upvotes,
                downvotes: response.data.data.downvotes || sample.votes.downvotes
              };
              
              return {
                ...sample,
                votes: updatedVotes
              };
            }
            return sample;
          })
        );

        setVotingStatus(prev => ({
          ...prev,
          [sampleId]: 'success'
        }));

        // Clear success status after 2 seconds
        setTimeout(() => {
          setVotingStatus(prev => {
            const newStatus = {...prev};
            delete newStatus[sampleId];
            return newStatus;
          });
        }, 2000);
      } else {
        setVotingStatus(prev => ({
          ...prev,
          [sampleId]: 'error'
        }));
        setError(response.data.message || 'Failed to record vote');
      }
    } catch (error) {
      console.error('Error voting on audio sample:', error);
      setVotingStatus(prev => ({
        ...prev,
        [sampleId]: 'error'
      }));
      setError(
        error.response?.data?.message || 
        'An error occurred while recording your vote. Please try again.'
      );
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="audio-sample-voting">
      <div className="voting-container">
      <div className="flex justify-end">
          <button className="back-button" onClick={() => window.history.back()}>
            &larr; Back
          </button>
      </div>
        {/* Logo and Title START */}
        <div className="flex flex-col items-center mb-2">
          <Link to="/" className="hover:opacity-100 transition-opacity">
            <img src="/images/logo5.png" alt="LangGuesser Logo" className="h-32 w-32" />
          </Link>
          <div className="text-center">
            <Link to="/" className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-200 hover:from-blue-400 hover:to-green-300 transition-colors">
              LangGuesser's
            </Link>
          </div>
        </div>
        {/* Logo and Title END */}
        <h2 className="text-3xl font-bold text-center text-white">Community Audio Samples</h2>
        
        <div className="voting-intro text-gray-100">
          <p>
            Listen to audio samples submitted by the LangGuesser community and vote on your favorites.<br />
            The most popular samples may be added to the game!
          </p>
          <p>
            Each user can only vote once per audio sample.
          </p>
        </div>

        <div className="filter-section">
          <h3 className="text-white text-xl font-bold mb-2">Filter Samples</h3>
          <div className="filter-controls">
            <div className="filter-group text-white">
              <label htmlFor="language">Language</label>
              <input
                type="text"
                id="language"
                name="language"
                value={filters.language}
                onChange={handleFilterChange}
                placeholder="Filter by language"
                className="filter-input"
              />
            </div>
            
            <div className="filter-group text-white">
              <label htmlFor="country">Country</label>
              <input
                type="text"
                id="country"
                name="country"
                value={filters.country}
                onChange={handleFilterChange}
                placeholder="Filter by country"
                className="filter-input"
              />
            </div>
            
            <div className="filter-group text-white">
              <label htmlFor="sortBy">Sort By</label>
              <select
                id="sortBy"
                name="sortBy"
                value={filters.sortBy}
                onChange={handleFilterChange}
                className="filter-select"
              >
                <option value="date">Date Submitted</option>
                <option value="votes">Popularity</option>
              </select>
            </div>
            
            <div className="filter-group text-white">
              <label htmlFor="order">Order</label>
              <select
                id="order"
                name="order"
                value={filters.order}
                onChange={handleFilterChange}
                className="filter-select"
              >
                <option value="desc">Descending</option>
                <option value="asc">Ascending</option>
              </select>
            </div>
          </div>
        </div>

        {error && <div className="error-message">{error}</div>}

        {loading ? (
          <div className="loading-message">Loading audio samples...</div>
        ) : audioSamples.length === 0 ? (
          <div className="no-samples-message text-gray-300 italic">
            <p>No audio samples found matching your criteria.</p>
          </div>
        ) : (
          <div className="audio-samples-grid">
            {audioSamples.map(sample => (
              <div key={sample._id} className="audio-sample-card text-white italic hover:text-gray-300 text-sm">
                <div className="sample-header">
                  <h3>{sample.language} ({sample.country})</h3>
                  <Link to={`/audio-samples/${sample._id}`}>View Details</Link>
                </div>
                
                <div className="sample-audio">
                  <iframe 
                    title={`Audio sample for ${sample.language} from ${sample.country}`}
                    src={sample.audioUrl} 
                    frameBorder="0" 
                    allow="autoplay"
                    className="audio-player w-full"
                  ></iframe>
                </div>
                
                <div className="sample-details">
                  {sample.dialect && (
                    <div className="detail-item">
                      <span className="detail-label">Dialect:</span>
                      <span className="detail-value">{sample.dialect}</span>
                    </div>
                  )}
                  
                  {sample.transcription && (
                    <div className="detail-item">
                      <span className="detail-label">Transcription:</span>
                      <span className="detail-value">{sample.transcription}</span>
                    </div>
                  )}
                  
                  <div className="detail-item">
                    <span className="detail-label">Submitted by:</span>
                    <span className="detail-value">
                      {sample.userId ? sample.userId.nickname : 'Anonymous'}
                    </span>
                  </div>
                  
                  <div className="detail-item">
                    <span className="detail-label">Date:</span>
                    <span className="detail-value">{formatDate(sample.submittedAt)}</span>
                  </div>
                </div>
                
                <div className="voting-section">
                  <div className="votes-display">
                    <span className="upvotes">👍 {sample.votes.upvotes}</span>
                    <span className="downvotes">👎 {sample.votes.downvotes}</span>
                  </div>
                  
                  <div className="voting-buttons">
                    <button
                      className={`vote-button upvote ${votingStatus[sample._id] === 'voting' ? 'voting' : ''}`}
                      onClick={() => handleVote(sample._id, 1)}
                      disabled={votingStatus[sample._id] === 'voting'}
                    >
                      {votingStatus[sample._id] === 'voting' ? 'Voting...' : 'Upvote'}
                    </button>
                    
                    <button
                      className={`vote-button downvote ${votingStatus[sample._id] === 'voting' ? 'voting' : ''}`}
                      onClick={() => handleVote(sample._id, -1)}
                      disabled={votingStatus[sample._id] === 'voting'}
                    >
                      {votingStatus[sample._id] === 'voting' ? 'Voting...' : 'Downvote'}
                    </button>
                  </div>
                  
                  {votingStatus[sample._id] === 'success' && (
                    <div className="vote-success">Vote recorded!</div>
                  )}
                  
                  {votingStatus[sample._id] === 'error' && (
                    <div className="vote-error">Error recording vote</div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      
      {/* Submit Audio Sample CTA */}
      <div className="submit-cta mt-8 p-6 bg-gradient-to-r from-purple-600 to-blue-500 rounded-lg text-white text-center">
        <h2 className="text-2xl font-bold mb-2">Have an accent to share?</h2>
        <p className="mb-4">Help improve LangGuesser by contributing your own audio samples!</p>
        <Link 
          to="/submit-audio" 
          className="inline-block px-6 py-2 bg-white text-purple-600 font-semibold rounded-lg hover:bg-gray-100 transition-colors"
        >
          Submit Audio Sample
        </Link>
      </div>
    </div>
  );
};

export default AudioSampleVoting;
