import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen flex flex-col items-center p-4">
      {/* Header Section */}
      <div className="text-center mb-5 items-center p-4 bg-black/50 backdrop-blur-sm rounded-xl border border-white/10">
        <Link to="/" className="text-4xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-200 hover:from-blue-400 hover:to-green-300 transition-colors">
          LangGuesser
        </Link>
        <h1 className="text-2xl font-bold mb-1 mt-4 text-white">Privacy Policy</h1>
        <p className="text-md mb-2 text-white italic">Last updated: February 18, 2025</p>
      </div>

      {/* Content Section */}
      <div className="w-full max-w-3xl bg-black/70 backdrop-blur-sm rounded-lg p-6 shadow-lg border border-white/10">
        <div className="space-y-6 text-white">
          <section className="bg-black/30 p-4 rounded-lg border border-white/10">
            <h2 className="text-xl font-semibold mb-2">1. Data Collection</h2>
            <p className="mb-2">We collect:</p>
            <ul className="list-disc pl-6 space-y-1 text-gray-300">
              <li>Email (for registered users)</li>
              <li>Game scores</li>
              <li>Newsletter preferences</li>
              <li>Google account info (if using Google sign-in)</li>
            </ul>
          </section>

          <section className="bg-black/30 p-4 rounded-lg border border-white/10">
            <h2 className="text-xl font-semibold mb-2">2. How We Use Your Data</h2>
            <ul className="list-disc pl-6 space-y-1 text-gray-300">
              <li>To maintain your account</li>
              <li>To track game progress</li>
              <li>To send newsletters (if opted in)</li>
              <li>To maintain the leaderboard</li>
            </ul>
          </section>

          <section className="bg-black/30 p-4 rounded-lg border border-white/10">
            <h2 className="text-xl font-semibold mb-2">3. Your Rights</h2>
            <p className="mb-2">You can:</p>
            <ul className="list-disc pl-6 space-y-1 text-gray-300">
              <li>Access your data</li>
              <li>Request data deletion</li>
              <li>Opt out of newsletters</li>
              <li>Export your data</li>
            </ul>
          </section>

          <section className="bg-black/30 p-4 rounded-lg border border-white/10">
            <h2 className="text-xl font-semibold mb-2">4. Contact</h2>
            <p className="text-gray-300">
              Questions? Email us at:{' '}
              <a href="mailto:privacy@langguesser.com" className="text-green-300 hover:text-green-500">
                privacy@langguesser.com
              </a>
            </p>
          </section>
        </div>
      </div>

      {/* Footer */}
      <div className="mt-6">
        <Link to="/" className="font-bold text-green-300 hover:text-green-500">
          Go back
        </Link>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
