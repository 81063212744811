import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { useAuth } from '../contexts/AuthContext';
import { Modal } from 'antd';

const Login = ({ logo }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState(null);
  const [showResendVerification, setShowResendVerification] = useState(false);
  const [resendVerificationStatus, setResendVerificationStatus] = useState(null);
  const { login, googleLogin, forgotPassword, resendVerification } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setShowResendVerification(false);

    try {
      const result = await login(email, password, rememberMe);
      if (result.success) {
        navigate('/dashboard');
      } else {
        setError(result.error || 'Failed to log in');
        
        // Check if the error is about unverified email
        if (result.error && result.error.includes('verify your email')) {
          setShowResendVerification(true);
        }
      }
    } catch (error) {
      setError(error.response?.data?.error || 'Failed to log in');
      
      // Check if the error is about unverified email
      if (error.response?.data?.error && error.response?.data?.error.includes('verify your email')) {
        setShowResendVerification(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResendVerification = async () => {
    try {
      setResendVerificationStatus({ loading: true });
      const response = await resendVerification(email);
      setResendVerificationStatus({ 
        success: true, 
        message: 'Verification email sent! Please check your inbox.' 
      });
    } catch (error) {
      setResendVerificationStatus({ 
        error: true, 
        message: error.response?.data?.error || 'Failed to resend verification email' 
      });
    }
  };

  const handleForgotPassword = async () => {
    try {
      setForgotPasswordStatus({ loading: true });
      const result = await forgotPassword(forgotPasswordEmail);
      
      if (result.success) {
        setForgotPasswordStatus({ 
          success: true, 
          message: 'Password reset email sent! Please check your inbox.' 
        });
      } else {
        setForgotPasswordStatus({ 
          error: true, 
          message: result.error 
        });
      }
    } catch (error) {
      setForgotPasswordStatus({ 
        error: true, 
        message: 'Failed to send password reset email' 
      });
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        setError('');
        setLoading(true);
        await googleLogin(tokenResponse.access_token);
        navigate('/dashboard');
      } catch (error) {
        setError('Failed to log in with Google: ' + (error.response?.data?.message || error.message));
      } finally {
        setLoading(false);
      }
    },
    onError: (error) => {
      setError('Google login failed: ' + error.message);
    },
    flow: 'auth-code',
  });

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="w-full max-w-md p-4">
        <div className="rounded-lg shadow-lg bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 text-white p-6">
          <div className="flex flex-col items-center mb-2">
            <Link to="/" className="hover:opacity-80 transition-opacity">
              <img src={logo} alt="LangGuesser Logo" className="h-32 w-32" />
            </Link>
          </div>
          <h2 className="text-2xl font-bold text-center mb-2">LangGuesser</h2>
          <h2 className="text-3xl font-bold text-center mb-2">Sign In</h2>
          {error && (
            <div className="bg-red-500/20 border border-red-500/30 text-red-200 p-3 rounded-md mb-4">
              {error}
              {showResendVerification && (
                <div className="mt-2">
                  <button 
                    onClick={handleResendVerification}
                    disabled={resendVerificationStatus?.loading}
                    className="text-white underline hover:text-white/80"
                  >
                    {resendVerificationStatus?.loading ? 'Sending...' : 'Resend verification email'}
                  </button>
                  
                  {resendVerificationStatus?.success && (
                    <div className="mt-1 text-green-200">
                      {resendVerificationStatus.message}
                    </div>
                  )}
                  
                  {resendVerificationStatus?.error && (
                    <div className="mt-1 text-red-200">
                      {resendVerificationStatus.message}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-white text-sm font-medium mb-2">
                Email
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 bg-white/10 border border-white/20 rounded-md text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/30"
                required
              />
            </div>

            <div>
              <label className="block text-white text-sm font-medium mb-2">
                Password
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 bg-white/10 border border-white/20 rounded-md text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/30"
                required
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-white/30 rounded bg-white/10"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-white">
                  Remember me
                </label>
              </div>
              <div className="text-sm">
                <button
                  type="button"
                  onClick={() => {
                    setIsModalVisible(true);
                    setForgotPasswordEmail(email);
                    setForgotPasswordStatus(null);
                  }}
                  className="text-white hover:text-white/80"
                >
                  Forgot password?
                </button>
              </div>
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? 'Signing in...' : 'Sign In'}
            </button>
          </form>

          <div className="mt-4 text-center">
            <span className="text-white/80 text-sm">Don't have an account? </span>
            <Link to="/register" className="text-white hover:text-white/80 text-sm font-medium">
              Sign Up
            </Link>
          </div>

          <div className="mt-4">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-white/20"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-transparent text-white/80">Or continue with</span>
              </div>
            </div>

            <div className="mt-6">
              <button
                onClick={() => handleGoogleLogin()}
                disabled={true}
                className="w-full flex items-center justify-center px-4 py-2 border border-white/20 rounded-md shadow-sm text-sm font-medium text-white/50 bg-white/5 cursor-not-allowed relative group"
              >
                <svg className="w-5 h-5 mr-2 opacity-50" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z"
                  />
                </svg>
                Google
                <span className="absolute -top-8 left-1/2 -translate-x-1/2 bg-black/90 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                  Temporarily unavailable 
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Forgot Password Modal */}
      <Modal
        title="Forgot Password"
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setForgotPasswordStatus(null);
        }}
        footer={null}
        centered
      >
        <div className="p-4">
          <p className="mb-4">
            Enter your email address and we'll send you a link to reset your password.
          </p>
          
          {forgotPasswordStatus?.success && (
            <div className="mb-4 p-3 bg-green-100 text-green-800 rounded-md">
              {forgotPasswordStatus.message}
            </div>
          )}
          
          {forgotPasswordStatus?.error && (
            <div className="mb-4 p-3 bg-red-100 text-red-800 rounded-md">
              {forgotPasswordStatus.message}
            </div>
          )}
          
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-medium mb-2">
              Email
            </label>
            <input
              type="email"
              value={forgotPasswordEmail}
              onChange={(e) => setForgotPasswordEmail(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          
          <div className="flex justify-end">
            <button
              onClick={handleForgotPassword}
              disabled={forgotPasswordStatus?.loading || !forgotPasswordEmail}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {forgotPasswordStatus?.loading ? 'Sending...' : 'Send Reset Link'}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Login;
