import React from 'react';
import { Link } from 'react-router-dom';

const UnsubscribeSuccess = ({ logo }) => {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="w-full max-w-md p-4">
        <div className="rounded-lg shadow-lg bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 text-white p-6">
          <div className="flex flex-col items-center mb-2">
            <Link to="/" className="hover:opacity-80 transition-opacity">
              <img src={logo} alt="LangGuesser Logo" className="h-32 w-32" />
            </Link>
          </div>
          <h2 className="text-2xl font-bold text-center mb-2">LangGuesser</h2>
          <h2 className="text-3xl font-bold text-center mb-4">Unsubscribed</h2>
          
          <div className="bg-white/10 border border-white/20 rounded-md p-4 mb-6">
            <p className="text-center text-white mb-4">
              You have been successfully unsubscribed from our newsletter.
            </p>
            <p className="text-center text-white">
              We're sorry to see you go! If you change your mind, you can always subscribe again from your profile settings.
            </p>
          </div>
          
          <div className="flex justify-center">
            <Link
              to="/"
              className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            >
              Return to Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnsubscribeSuccess;
