import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="text-center mt-4">
      <div className="text-white/40 text-sm">
        <Link to="/privacy" className="hover:text-white/60">Privacy Policy</Link>
        <span className="mx-2">|</span>
        <Link to="/terms" className="hover:text-white/60">Terms of Service</Link>
      </div>
    </div>
  );
};

export default Footer;
