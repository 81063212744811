import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const UnsubscribeRedirect = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  
  useEffect(() => {
    const handleUnsubscribe = async () => {
      try {
        // Call the backend unsubscribe endpoint
        await axios.get(`/api/auth/unsubscribe/${token}`);
        // Redirect to success page
        navigate('/unsubscribe-success');
      } catch (error) {
        console.error('Error unsubscribing:', error);
        // Redirect to error page or home page
        navigate('/');
      }
    };
    
    handleUnsubscribe();
  }, [token, navigate]);
  
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="w-full max-w-md p-4">
        <div className="rounded-lg shadow-lg bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 text-white p-6">
          <div className="flex flex-col items-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
            <h2 className="text-xl font-bold mt-4">Processing your unsubscribe request...</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnsubscribeRedirect;
