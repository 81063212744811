import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, Link, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Modal } from 'antd';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import WelcomeModal from './components/WelcomeModal';
import UpdatePopup from './components/UpdatePopup';
import UnsubscribeRedirect from './components/UnsubscribeRedirect';

// Components
import Game from './components/Game';
import EasyMode from './components/EasyMode';
import Leaderboard from './components/Leaderboard';
import EasyLeaderboard from './components/EasyLeaderboard';
import Languages from './components/Languages';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import UserMenu from './components/UserMenu';
import NicknameInput from './components/NicknameInput';
import Instructions from './components/Instructions';
import Changelog from './components/Changelog';
import Roadmap from './components/Roadmap';
import EmailVerification from './components/EmailVerification';
import ResetPassword from './components/ResetPassword';
import AdminDashboard from './components/AdminDashboard';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import Footer from './components/Footer';
import WaitingRoom from './components/multiplayer/WaitingRoom';
import UnsubscribeSuccess from './components/UnsubscribeSuccess';

// Audio Samples Components
import AudioSampleSubmission from './components/audioSamples/AudioSampleSubmission';
import AudioSampleVoting from './components/audioSamples/AudioSampleVoting';
import AdminAudioSamples from './components/audioSamples/AdminAudioSamples';
import AudioSampleDetails from './components/audioSamples/AudioSampleDetails';
import UserAudioSamples from './components/audioSamples/UserAudioSamples';

function App() {
  const [showInstructions, setShowInstructions] = useState(false);
  const [showChangelog, setShowChangelog] = useState(false);
  const [showRoadmap, setShowRoadmap] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [nickname, setNickname] = useState('');
  const [shake, setShake] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMaintenanceModalVisible, setIsMaintenanceModalVisible] = useState(false);
  const [isGameModeModalVisible, setIsGameModeModalVisible] = useState(false);

  // Background image rotation
  const [currentBackground, setCurrentBackground] = useState('/images/background.webp');
  const [currentLogo, setCurrentLogo] = useState('/images/logo3.png');

  // List of logos and backgrounds
  const logos = [
    '/images/logo3.png',
    '/images/logo4.png',
    '/images/logo5.png',
    '/images/logo6.png',
  ];

  const backgrounds = [
    '/images/bg.png',
    '/images/bg2.png',
    '/images/bg3.png',
    '/images/bg4.png',
  ];

  const determineAssetsByHour = () => {
    const currentCETHour = new Date().toLocaleString('en-US', {
      timeZone: 'Europe/Paris',
      hour: '2-digit',
      hour12: false,
    });

    const index = parseInt(currentCETHour, 10) % logos.length;
    setCurrentLogo(logos[index]);
    setCurrentBackground(backgrounds[index]);
  };

  useEffect(() => {
    determineAssetsByHour();
    const intervalId = setInterval(determineAssetsByHour, 3600000); // Update every hour

    return () => clearInterval(intervalId);
  }, []);

  const handleSoloPlayClick = () => {
    if (!localStorage.getItem('token') && !sessionStorage.getItem('token') && !nickname?.trim()) {
      setIsError(true);
      setShake(true);
      return;
    }
    
    if (!localStorage.getItem('token') && !sessionStorage.getItem('token')) {
      localStorage.setItem('nickname', nickname);
    }
    
    setIsGameModeModalVisible(true);
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <AuthProvider>
        <div 
          className="min-h-screen bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${currentBackground})`,
          }}
        >
          {/* User Menu - Top Right */}
          <div className="absolute top-4 right-4 z-50">
            <UserMenu />
          </div>

          {location.pathname === '/' && <WelcomeModal />}

          {/* Main Content */}
          <Routes>
            <Route path="/login" element={<Login logo={currentLogo} />} />
            <Route path="/register" element={<Register logo={currentLogo} />} />
            <Route path="/verify-email/:token" element={<EmailVerification />} />
            <Route path="/reset-password/:token" element={<ResetPassword logo={currentLogo} />} />
            <Route path="/unsubscribe/:token" element={<UnsubscribeRedirect />} />
            <Route path="/unsubscribe-success" element={<UnsubscribeSuccess logo={currentLogo} />} />
            <Route path="/languages" element={<Languages />} />
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/easy-leaderboard" element={<EasyLeaderboard />} />
            <Route path="/game" element={<Game />} />
            <Route path="/easy-mode" element={<EasyMode />} />
            <Route path="/multiplayer" element={<WaitingRoom />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <ProtectedRoute adminOnly={true}>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
            {/* Audio Samples Routes */}
            <Route
              path="/submit-audio"
              element={
                <ProtectedRoute>
                  <AudioSampleSubmission />
                </ProtectedRoute>
              }
            />
            <Route path="/audio-voting" element={<AudioSampleVoting />} />
            <Route path="/community-audio-samples" element={<Navigate to="/audio-voting" replace />} />
            <Route path="/user-audio-samples" element={<UserAudioSamples />} />
            <Route
              path="/admin/audio-samples"
              element={
                <ProtectedRoute adminOnly={true}>
                  <AdminAudioSamples />
                </ProtectedRoute>
              }
            />
            <Route path="/audio-samples/:id" element={<AudioSampleDetails />} />
            <Route
              path="/"
              element={
                <div className="min-h-screen flex flex-col items-center justify-center p-4">
                  <div className="w-full max-w-md p-4">
                    {/* Homepage Card */}
                    <div className="rounded-lg shadow-lg bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 text-white p-6">
                      <div className="flex justify-center mb-2">
                        <img src={currentLogo} alt="Logo" className="w-32 h-32" />
                      </div>
                      <h1 className="text-4xl font-bold text-center mb-2">LangGuesser</h1>
                      <p className="text-center mb-6">
                        Guess the language's country by its accent
                      </p>
                      {!localStorage.getItem('token') && !sessionStorage.getItem('token') ? (
                        <>
                          <NicknameInput 
                            onNicknameChange={(value) => setNickname(value)} 
                            isError={isError}
                            setIsError={setIsError}
                            shake={shake}
                            setShake={setShake}
                          />
                          <button
                            type="submit"
                            onClick={() => {
                              if (!nickname?.trim()) {
                                setIsError(true);
                                setShake(true);
                                return;
                              }
                              setIsModalVisible(true);
                            }}
                            className="p-[3px] relative mb-4 flex items-center justify-center w-full"
                          >
                            <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg w-full" />
                            <div className="px-8 py-2 bg-black rounded-[6px] relative group transition duration-200 text-white hover:bg-transparent flex items-center justify-center w-full">
                              Play Now
                            </div>
                          </button>
                          <div className="flex justify-center space-x-4 text-white/80 mt-2">
                            <button
                              onClick={() => {
                                if (!nickname?.trim()) {
                                  setIsError(true);
                                  setShake(true);
                                  return;
                                }
                                localStorage.setItem('nickname', nickname);
                                handleSoloPlayClick();
                              }}
                              className="text-white hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-purple-400 hover:to-pink-400 transition-all duration-300 transform hover:scale-110"
                            >
                              Solo Play
                            </button>
                            <span>|</span>
                            <button
                              onClick={() => {
                                if (!nickname?.trim()) {
                                  setIsError(true);
                                  setShake(true);
                                  return;
                                }
                                localStorage.setItem('nickname', nickname);
                                localStorage.setItem('gameMode', 'multiplayer');
                                navigate('/multiplayer');
                              }}
                              className="text-white hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-purple-400 hover:to-pink-400 transition-all duration-300 transform hover:scale-110"
                            >
                              Multiplayer
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => setIsModalVisible(true)}
                            className="p-[3px] relative mb-4 flex items-center justify-center w-full"
                          >
                            <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg w-full" />
                            <div className="px-8 py-2 bg-black rounded-[6px] relative group transition duration-200 text-white hover:bg-transparent flex items-center justify-center w-full">
                              Play Now
                            </div>
                          </button>
                          <div className="flex justify-center space-x-4 text-white/80 mt-2">
                            <button
                              onClick={() => {
                                localStorage.setItem('gameMode', 'solo');
                                handleSoloPlayClick();
                              }}
                              className="text-white hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-purple-400 hover:to-pink-400 transition-all duration-300 transform hover:scale-110"
                            >
                              Solo Play
                            </button>
                            <span>|</span>
                            <button
                              onClick={() => {
                                localStorage.setItem('gameMode', 'multiplayer');
                                navigate('/multiplayer');
                              }}
                              className="text-white hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-purple-400 hover:to-pink-400 transition-all duration-300 transform hover:scale-110"
                            >
                              Multiplayer
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {/* Footer Links - Outside the card */}
                  <div className="text-center mt-2">
                    <p className="text-center text-sm mb-4">
                      <Link to="/audio-voting" className="text-white hover:text-white/80">
                        Check out audio samples from the community and vote on your favorites!
                      </Link>
                      <span className="text-green-500 text-xs italic"> beta</span>
                    </p>
                    <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4 text-blue-200">
                      <button
                        className="focus:outline-none hover:text-blue-400"
                        onClick={() => setShowInstructions(true)}
                      >
                        How to Play
                      </button>
                      <span className="hidden sm:inline text-white">|</span>
                      <button
                        className="focus:outline-none hover:text-blue-400"
                        onClick={() => setShowChangelog(true)}
                      >
                        Changelog
                      </button>
                      <span className="hidden sm:inline text-white">|</span>
                      <button
                        className="focus:outline-none hover:text-blue-400"
                        onClick={() => setShowRoadmap(true)}
                      >
                        Roadmap
                      </button>
                    </div>
                  </div>

                  {/* "Buy Me a Coffee" Link */}
                  <div className="text-center mt-4">
                    <a
                      href="https://buymeacoffee.com/langguesser"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-yellow-300 hover:text-yellow-400 transition-colors"
                    >
                      Buy me a coffee ☕
                    </a>
                  </div>

                  <Footer />

                  {/* Game Mode Modal */}
                  <Modal
                    title={
                      <div className="text-center font-bold text-xl bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-blue-500">
                        Choose Game Mode
                      </div>
                    }
                    open={isModalVisible}
                    onCancel={() => setIsModalVisible(false)}
                    footer={null}
                    centered
                    className="game-mode-modal"
                  >
                    <div className="flex flex-col space-y-4 p-4">
                      <button
                        onClick={() => {
                          if (!localStorage.getItem('token') && !sessionStorage.getItem('token') && !nickname?.trim()) {
                            setIsError(true);
                            setShake(true);
                            setIsModalVisible(false);
                            return;
                          }
                          if (!localStorage.getItem('token') && !sessionStorage.getItem('token')) {
                            localStorage.setItem('nickname', nickname);
                          }
                          localStorage.setItem('gameMode', 'solo');
                          setIsModalVisible(false);
                          handleSoloPlayClick();
                        }}
                        className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                      >
                        Solo Play
                      </button>
                      <button
                        onClick={() => {
                          if (!localStorage.getItem('token') && !sessionStorage.getItem('token') && !nickname?.trim()) {
                            setIsError(true);
                            setShake(true);
                            setIsModalVisible(false);
                            return;
                          }
                          if (!localStorage.getItem('token') && !sessionStorage.getItem('token')) {
                            localStorage.setItem('nickname', nickname);
                          }
                          localStorage.setItem('gameMode', 'multiplayer');
                          setIsModalVisible(false);
                          navigate('/multiplayer');
                        }}
                        className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                      >
                        1v1 Multiplayer
                      </button>
                    </div>
                  </Modal>

                  {/* Game Mode Selection Modal */}
                  <Modal
                    title={
                      <div className="text-center font-bold text-xl bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-blue-500">
                        Ready to guess?
                      </div>
                    }
                    open={isGameModeModalVisible}
                    onCancel={() => setIsGameModeModalVisible(false)}
                    footer={null}
                    centered
                    className="game-mode-selection-modal"
                  >
                    <div className="flex flex-col space-y-6 p-4">
                      <div 
                        onClick={() => {
                          setIsGameModeModalVisible(false);
                          navigate('/easy-mode');
                        }}
                        className="cursor-pointer bg-gradient-to-r from-purple-100 to-blue-100 hover:from-purple-200 hover:to-blue-200 p-4 rounded-lg border border-purple-300 transition-all duration-300 transform hover:scale-[1.02] hover:shadow-md"
                      >
                        <div className="flex items-center space-x-3">
                          <span className="text-2xl mb-4">🌍</span>
                          <h3 className="text-lg font-bold text-purple-700">Easy Mode</h3>
                        </div>
                        <p className="text-gray-700">
                          Guess any country that speaks the language you hear in the audio clip. Perfect for beginners!
                        </p>
                      </div>
                      
                      <div 
                        onClick={() => {
                          setIsGameModeModalVisible(false);
                          navigate('/game');
                        }}
                        className="cursor-pointer bg-gradient-to-r from-blue-100 to-indigo-100 hover:from-blue-200 hover:to-indigo-200 p-4 rounded-lg border border-blue-300 transition-all duration-300 transform hover:scale-[1.02] hover:shadow-md"
                      >
                        <div className="flex items-center space-x-3">
                          <span className="text-2xl mb-4">🎯</span>
                          <h3 className="text-lg font-bold text-blue-700">Classic Mode</h3>
                        </div>
                        <p className="text-gray-700">
                          Identify the exact country the accent is from. A true test of your language recognition skills!
                        </p>
                      </div>
                    </div>
                  </Modal>

                  {/* Other Modals */}
                  <Instructions isOpen={showInstructions} onClose={() => setShowInstructions(false)} />
                  <Changelog isOpen={showChangelog} onClose={() => setShowChangelog(false)} />
                  <Roadmap isOpen={showRoadmap} onClose={() => setShowRoadmap(false)} />
                </div>
              }
            />
          </Routes>
          {/* Update Popup */}
          <UpdatePopup />
        </div>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
