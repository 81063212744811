import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import geojsonData from '../countries.json';
import './MultiplayerGame.css';
import { useAuth } from '../../contexts/AuthContext';

const defaultCountryStyle = {
  fillColor: '#3B82F6',
  fillOpacity: 0.1,
  color: '#1D4ED8',
  weight: 1
};

const selectedCountryStyle = {
  fillColor: '#60A5FA',
  fillOpacity: 0.7,
  color: '#1D4ED8',
  weight: 2
};

const correctCountryStyle = {
  fillColor: '#059669',
  fillOpacity: 0.7,
  color: '#047857',
  weight: 2
};

const wrongCountryStyle = {
  fillColor: '#DC2626',
  fillOpacity: 0.7,
  color: '#B91C1C',
  weight: 2
};

// Exception on the map for UK regions
const ukRegions = {
  coordinates: [
    { name: "Scotland", bounds: [[54.6, -8.2], [58.6, -0.7]] },
    { name: "Wales", bounds: [[51.3, -5.3], [53.4, -2.6]] },
    { name: "Northern Ireland", bounds: [[54.0, -8.2], [55.3, -5.4]] },
    { name: "England", bounds: [[50.0, -5.7], [55.8, 1.8]] }
  ]
};

const isPointInBounds = (point, bounds) => {
  return point[0] >= bounds[0][0] && 
         point[0] <= bounds[1][0] && 
         point[1] >= bounds[0][1] && 
         point[1] <= bounds[1][1];
};

const getUKRegion = (lat, lng) => {
  const point = [lat, lng];
  for (const region of ukRegions.coordinates) {
    if (isPointInBounds(point, region.bounds)) {
      return region.name;
    }
  }
  return "England"; // Default to England if no specific region is matched
};

const MultiplayerGame = ({ socket, matchData, onGameEnd }) => {
  const navigate = useNavigate();
  const { currentUser, token } = useAuth();
  const [gameState, setGameState] = useState({
    round: 1,
    scores: {},
    roundTimer: 30,
    hasAnswered: false,
    showRoundResults: false,
    roundResults: [],
    isRoundStarted: false,
    currentAudio: null,
    waitingForOpponent: false,
    gameEnded: false,
    finalScores: {}
  });

  const [countryStylesState, setCountryStylesState] = useState({});
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [roundCountdown, setRoundCountdown] = useState(3);
  const [countdown, setCountdown] = useState(null);
  const [debugState, setDebugState] = useState({});
  const audioRef = useRef(null);
  const timerIdRef = useRef(null);
  const [blink, setBlink] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Debug helper to track state changes
  useEffect(() => {
    setGameState(prev => prev);
  }, [gameState]);

  useEffect(() => {
    // No logging needed
  }, [countryStylesState]);

  useEffect(() => {
    // No logging needed
  }, [matchData]);

  useEffect(() => {
    if (!matchData) return;

    setGameState(prev => ({
      ...prev,
      round: 1,
      scores: {},
      roundTimer: 30,
      hasAnswered: false,
      showRoundResults: false,
      roundResults: null,
      isRoundStarted: false,
      currentAudio: null,
      waitingForOpponent: false,
      gameEnded: false,
      finalScores: {}
    }));
    setIsLoading(false);
  }, [matchData]);

  useEffect(() => {
    if (!socket) return;

    const handleMessage = (event) => {
      const message = JSON.parse(event.data);
      console.log('Received websocket message:', message);

      switch (message.type) {
        case 'GAME_START':
          setGameState(prev => ({
            ...prev,
            round: message.data.round,
            scores: message.data.scores || {},
            hasAnswered: false,
            waitingForOpponent: false,
            showRoundResults: false,
            roundResults: null,
            isRoundStarted: false,
            roundTimer: 30,
            currentAudio: null
          }));
          setRoundCountdown(3);
          setSelectedCountry(null);
          setCountryStylesState({});
          break;

        case 'ROUND_START':
          // First, update the audio source
          if (audioRef.current && message.data.audioUrl) {
            audioRef.current.src = message.data.audioUrl;
            audioRef.current.load();
          }
          
          // Then update the game state
          setGameState(prevState => {
            const newState = {
              ...prevState,
              round: message.data.round,
              roundTimer: 30,
              hasAnswered: false,
              showRoundResults: false,
              roundResults: null,
              isRoundStarted: true, // Set to true immediately
              currentAudio: message.data.audioUrl,
              waitingForOpponent: false
            };
            
            // Force update the DOM element
            setTimeout(() => {
              const element = document.getElementById('isRoundStartedValue');
              if (element) {
                element.textContent = 'true';
              }
            }, 0);
            
            return newState;
          });
          
          // Reset other state
          setSelectedCountry(null);
          setCountryStylesState({});
          
          // Play audio after a short delay
          setTimeout(() => {
            if (audioRef.current) {
              audioRef.current.play().catch(error => {
                console.error('Failed to play audio:', error);
              });
            }
          }, 500);
          break;

        case 'TIMER_UPDATE':
          setGameState(prev => ({
            ...prev,
            roundTimer: message.data.timeLeft
          }));
          break;

        case 'ROUND_RESULTS':
          if (timerIdRef.current) {
            clearInterval(timerIdRef.current);
          }
          setGameState(prev => ({
            ...prev,
            showRoundResults: true,
            roundResults: message.data.results,
            scores: message.data.scores,
            waitingForOpponent: false,
            isRoundStarted: false,
            currentAudio: null
          }));
          setTimeout(() => {
            setGameState(prev => ({
              ...prev,
              showRoundResults: false
            }));
          }, 3000);
          break;

        case 'GAME_END':
          if (timerIdRef.current) {
            clearInterval(timerIdRef.current);
          }
          
          // Get my score from the final scores
          console.log('Game end data received:', message.data);
          console.log('My player ID:', matchData.playerId);
          console.log('Score keys:', Object.keys(message.data.scores));
          console.log('matchData:', matchData);
          
          const myScore = message.data.scores[matchData.playerId] || 0;
          console.log('Game ended with my score:', myScore, 'Player ID:', matchData.playerId, 'Scores:', message.data.scores);
          
          // Submit game results to server if user is logged in
          if (currentUser && token) {
            submitGameResults(myScore, message.data.totalPlayers);
          }
          
          setGameState(prev => ({
            ...prev,
            showRoundResults: false,
            isRoundStarted: false,
            gameEnded: true,
            finalScores: message.data.finalScores || message.data.scores
          }));
          break;

        default:
          break;
      }
    };

    socket.addEventListener('message', handleMessage);
    return () => socket.removeEventListener('message', handleMessage);
  }, [socket, onGameEnd]);

  // Handle audio setup when currentAudio changes
  useEffect(() => {
    if (gameState.currentAudio) {
      // Set up audio
      if (audioRef.current) {
        audioRef.current.src = gameState.currentAudio;
        audioRef.current.load();
        audioRef.current.play().catch(error => {
          // Handle autoplay restrictions
        });
      }
    }
  }, [gameState.currentAudio]);

  const handleCountryClick = (countryName) => {
    // Get the current state directly from the DOM to avoid stale closures
    const isRoundStarted = document.getElementById('isRoundStartedValue').textContent === 'true';
    
    if (!isRoundStarted || gameState.hasAnswered) {
      return;
    }

    setSelectedCountry(countryName);
    socket.send(JSON.stringify({ 
      type: 'SUBMIT_ANSWER', 
      data: { 
        answer: countryName,
        matchId: matchData.matchId
      } 
    }));
    
    setGameState(prev => ({
      ...prev,
      hasAnswered: true,
      waitingForOpponent: true
    }));
    
    // Also update the country style
    setCountryStylesState(prev => ({
      ...prev,
      [countryName]: selectedCountryStyle
    }));
  };

  const getCountryStyle = (feature) => {
    const countryName = feature.properties.ADMIN;
    
    // Return the current style for this country if it exists
    if (countryStylesState[countryName]) {
      return {
        ...defaultCountryStyle,
        ...countryStylesState[countryName]
      };
    }
    
    // If this country was selected and we're waiting for results
    if (selectedCountry === countryName && gameState.hasAnswered) {
      return {
        ...defaultCountryStyle,
        ...selectedCountryStyle
      };
    }

    // Check round results
    if (gameState.showRoundResults) {
      const result = gameState.roundResults?.find(r => r.answer === countryName);
      if (result) {
        return {
          ...defaultCountryStyle,
          ...(result.correct ? correctCountryStyle : wrongCountryStyle)
        };
      }
    }

    // Default style
    return defaultCountryStyle;
  };

  const onEachCountry = (feature, layer) => {
    const countryName = feature.properties.ADMIN;

    // Make sure layer is interactive
    layer.options.interactive = true;

    layer.bindTooltip(() => countryName, {
      permanent: false,
      sticky: true,
      direction: 'auto',
      className: 'country-tooltip'
    });

    layer.on({
      click: (e) => {
        // !!IMPORTANT!! Get the current state directly from the component state
        // This ensures we have the latest values
        const isRoundStarted = document.getElementById('isRoundStartedValue').textContent === 'true';
        
        if (!isRoundStarted || gameState.hasAnswered) {
          return;
        }

        if (countryName === "United Kingdom") {
          const clickedPoint = e.latlng;
          const region = getUKRegion(clickedPoint.lat, clickedPoint.lng);
          handleCountryClick(region);
        } else {
          handleCountryClick(countryName);
        }

        // Update the style immediately
        const newStyle = {
          ...defaultCountryStyle,
          fillColor: selectedCountryStyle.fillColor,
          fillOpacity: selectedCountryStyle.fillOpacity
        };
        layer.setStyle(newStyle);
      },
      mouseover: (e) => {
        if (gameState.hasAnswered) return;
        const currentStyle = getCountryStyle(feature);
        layer.setStyle({
          ...currentStyle,
          fillOpacity: 0.7
        });
        if (countryName === "United Kingdom") {
          const point = e.latlng;
          const region = getUKRegion(point.lat, point.lng);
          layer.setTooltipContent(region);
        }
      },
      mouseout: (e) => {
        if (gameState.hasAnswered) return;
        const currentStyle = getCountryStyle(feature);
        layer.setStyle(currentStyle);
        if (countryName === "United Kingdom") {
          layer.setTooltipContent(countryName);
        }
      }
    });
  };

  const renderRoundResults = () => {
    if (!gameState.showRoundResults) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-gray-800 p-8 rounded-lg text-white text-center max-w-2xl w-full">
          <h3 className="text-2xl font-bold mb-4">Round Results</h3>
          <div className="space-y-4">
            {gameState.roundResults?.map((result, index) => (
              <div key={index} className={`p-4 rounded-lg ${result.correct ? 'bg-green-800' : 'bg-red-800'}`}>
                <p className="text-lg font-semibold">
                  {matchData.players.find(p => p.id === result.playerId)?.nickname || 'Guest'}
                </p>
                <div className="flex justify-between items-center mt-2">
                  <span>Answer: {result.answer || 'No answer'}</span>
                  <span>Time Bonus: +{result.timeBonus * 10}</span>
                  <span className="font-bold">Points: {result.points}</span>
                </div>
                <p className="mt-2">Total Score: {result.totalScore}</p>
              </div>
            ))}
          </div>
          <p className="mt-6 text-gray-400">Next round starting soon...</p>
        </div>
      </div>
    );
  };

  // Function to submit game results to the server
  const submitGameResults = async (score, totalPlayers) => {
    try {
      console.log('Submitting game results:', { score, totalPlayers });
      
      // Make sure score is a number
      const numericScore = parseInt(score, 10) || 0;
      console.log('Converted score to numeric:', numericScore);
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/games/submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          score: numericScore,
          type: 'multiplayer',
          gameMode: 'multiplayer',
          totalPlayers
        })
      });

      const data = await response.json();
      if (data.success) {
        console.log('Multiplayer game results saved successfully');
      } else {
        console.error('Failed to save game results:', data.error);
      }
    } catch (error) {
      console.error('Error submitting game results:', error);
    }
  };

  if (countdown !== null) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800">
        <div className="text-4xl font-bold text-white mb-4">
          Round {gameState.round} starting in
        </div>
        <div className="text-6xl font-bold text-white">
          {countdown}
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800">
        <div className="text-6xl font-bold text-white">Loading...</div>
      </div>
    );
  }

  if (gameState.gameEnded) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800">
        <div className="text-6xl font-bold text-white mb-8">Game Over!</div>
        <div className="bg-white/10 rounded-lg p-8">
          <div className="text-3xl font-bold text-white mb-4">Final Scores:</div>
          {Object.entries(gameState.finalScores).map(([playerId, score]) => (
            <div key={playerId} className="text-2xl text-white mb-2">
              {matchData.players.find(p => p.id === playerId)?.nickname || 'Guest'}: {score}
            </div>
          ))}
          <Link
            to="/"
            className="mt-8 bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded inline-block"
          >
            Back to Menu
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800">
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-8">
          {matchData ? (
            <>
              <div className="text-center">
                {/* Empty div to maintain layout */}
                <div className="w-24"></div>
              </div>
              <div className="text-center">
                <Link to="/" className="text-3xl font-bold text-white hover:text-primary">
                  LangGuesser
                </Link>
                <h2 className="text-2xl font-bold text-white">Round {gameState.round} / 10</h2>
                <div className={`text-xl font-bold ${blink ? 'animate-pulse text-red-500' : 'text-primary'}`}>
                  {roundCountdown > 0 ? `${roundCountdown}s` : ''}
                </div>
              </div>
              <div className="text-center">
                {/* Empty div to maintain layout */}
                <div className="w-24"></div>
              </div>
            </>
          ) : (
            <div className="w-full text-center">
              <Link to="/" className="text-3xl font-bold text-white hover:text-primary">
                LangGuesser
              </Link>
            </div>
          )}
        </div>

        {gameState.currentAudio && (
          <div className="flex justify-center items-center mb-4">
            <audio
              ref={audioRef}
              src={gameState.currentAudio}
              preload="auto"
              className="hidden"
            >
              Your browser does not support the audio element.
            </audio>
            <button
              onClick={() => {
                if (audioRef.current) {
                  audioRef.current.currentTime = 0;
                  audioRef.current.play().catch(console.error);
                }
              }}
              className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded-full shadow-lg transition-colors duration-200 flex items-center"
              disabled={gameState.hasAnswered}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
              </svg>
              Replay
            </button>
          </div>
        )}

        <div className="relative w-full h-[70vh] rounded-lg overflow-hidden shadow-lg">
          <MapContainer
            center={[20, 0]}
            zoom={2}
            style={{ height: '100%', width: '100%' }}
            zoomControl={false}
            attributionControl={false}
            maxBounds={[[-90, -180], [90, 180]]}
            minZoom={2}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <GeoJSON
              data={geojsonData}
              style={getCountryStyle}
              onEachFeature={onEachCountry}
            />
          </MapContainer>
        </div>

        {gameState.showRoundResults && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-gray-800 p-8 rounded-lg text-white text-center max-w-2xl w-full">
              <h3 className="text-2xl font-bold mb-4">Round Results</h3>
              <div className="space-y-4">
                {gameState.roundResults?.map((result, index) => (
                  <div key={index} className={`p-4 rounded-lg ${result.correct ? 'bg-green-800' : 'bg-red-800'}`}>
                    <p className="text-lg font-semibold">
                      {matchData.players.find(p => p.id === result.playerId)?.nickname || 'Guest'}
                    </p>
                    <div className="flex justify-between items-center mt-2">
                      <span>Answer: {result.answer || 'No answer'}</span>
                      <span>Time Bonus: +{result.timeBonus * 10}</span>
                      <span className="font-bold">Points: {result.points}</span>
                    </div>
                    <p className="mt-2">Total Score: {result.totalScore}</p>
                  </div>
                ))}
              </div>
              <p className="mt-6 text-gray-400">Next round starting soon...</p>
            </div>
          </div>
        )}

        <div className="absolute top-4 left-4 z-10 bg-gray-800 p-4 rounded-lg shadow-lg">
          <h2 className="text-white text-xl font-bold mb-2">Round {gameState.round}/10</h2>
          <div className="flex justify-between items-center mb-2">
            <span className="text-white">Time:</span>
            <span className={`text-white font-bold ${gameState.roundTimer <= 10 ? 'text-red-500' : ''}`}>
              {gameState.roundTimer}s
            </span>
          </div>
          <div className="mb-4">
            <h3 className="text-white mb-1">Scores:</h3>
            {matchData && matchData.players && matchData.players.map(player => (
              <div key={player.id} className="flex justify-between">
                <span className="text-white">{player.nickname}:</span>
                <span className="text-white font-bold">{gameState.scores[player.id] || 0}</span>
              </div>
            ))}
          </div>
          <div className="text-center">
            <div className="text-white mb-2">
              {gameState.hasAnswered ? (
                gameState.waitingForOpponent ? 'Waiting for opponent...' : 'Round complete!'
              ) : (
                'Guess the country!'
              )}
            </div>
          </div>
          <div id="isRoundStartedValue" style={{display: 'none'}}>{String(gameState.isRoundStarted)}</div>
        </div>
      </div>
    </div>
  );

};

export default MultiplayerGame;
