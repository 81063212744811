import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import MatchFoundPopup from './MatchFoundPopup';
import MultiplayerGame from './MultiplayerGame';
import { Link } from 'react-router-dom';
import './WaitingRoom.css';

const WaitingRoom = () => {
  const { user, token, currentUser } = useAuth();
  const [status, setStatus] = useState('connecting');
  const [socket, setSocket] = useState(null);
  const [inQueue, setInQueue] = useState(false);
  const [playersOnline, setPlayersOnline] = useState(0);
  const [playersInQueue, setPlayersInQueue] = useState(0);
  const [matchData, setMatchData] = useState(null);
  const [gameStarted, setGameStarted] = useState(false);
  const [connectionError, setConnectionError] = useState(null);
  const [playSoundOnMatch, setPlaySoundOnMatch] = useState(() => {
    const savedPreference = localStorage.getItem('playSoundOnMatch');
    return savedPreference !== null ? JSON.parse(savedPreference) : true;
  });
  const matchSoundRef = useRef(null);

  useEffect(() => {
    let mounted = true;

    const connectWebSocket = () => {
      try {
        // Use Heroku URL for WebSocket in production
        const wsUrl = process.env.NODE_ENV === 'production'
          ? `wss://mighty-headland-49509-56ec7d863bb1.herokuapp.com/multiplayer${token ? `?token=${token}` : ''}`
          : `ws://localhost:5001/multiplayer${token ? `?token=${token}` : ''}`;
        
        console.log('Connecting to WebSocket:', wsUrl);
        const newSocket = new WebSocket(wsUrl);
        setSocket(newSocket);
        
        // Store connection attempt status
        let connectionAttemptActive = true;
        
        newSocket.onopen = () => {
          if (!mounted) return;
          setStatus('Connected');
          
          // Send authentication message if user is logged in
          if (token) {
            newSocket.send(JSON.stringify({
              type: 'AUTHENTICATE',
              data: { token }
            }));
          }
        };
        
        newSocket.onclose = () => {
          if (!mounted) return;
          
          setStatus('Disconnected from server');
          setConnectionError('Connection to server lost. Please refresh the page.');
        };
        
        newSocket.onerror = (error) => {
          if (!mounted) return;
          
          console.error('WebSocket error:', error);
          setConnectionError('Error connecting to server. Please refresh the page.');
        };
        
        newSocket.onmessage = (event) => {
          if (!mounted) return;
          
          const data = JSON.parse(event.data);
          console.log('Received:', data);
          
          switch (data.type) {
            case 'CONNECTION_ESTABLISHED':
              if (data.data.isGuest) {
                setStatus(`Connected as ${data.data.nickname}`);
              } else {
                setStatus(`Connected as ${data.data.nickname}`);
              }
              break;
              
            case 'QUEUE_JOIN_SUCCESS':
              setStatus(`In queue - Position: ${data.data.position}`);
              setInQueue(true);
              setPlayersInQueue(data.data.totalInQueue || 1);
              break;
              
            case 'QUEUE_LEAVE_SUCCESS':
              setStatus('Connected');
              setInQueue(false);
              break;

            case 'PLAYERS_UPDATE':
              setPlayersOnline(data.data.playersOnline);
              setPlayersInQueue(data.data.playersInQueue);
              break;
              
            case 'MATCH_FOUND':
              console.log('MATCH_FOUND data:', data.data);
              // Log the structure to see exactly what's in the data
              console.log('MATCH_FOUND opponent structure:', JSON.stringify(data.data.opponent, null, 2));
              setMatchData(data.data);
              
              // Play sound if enabled
              if (playSoundOnMatch && matchSoundRef.current) {
                matchSoundRef.current.play().catch(err => {
                  console.error('Error playing match found sound:', err);
                });
              }
              break;

            case 'GAME_START':
              setGameStarted(true);
              setMatchData(prev => {
                const updatedData = {
                  ...prev,
                  ...data.data,
                  playerId: prev.playerId // Preserve the playerId from MATCH_FOUND
                };
                console.log('Updated match data with GAME_START:', updatedData);
                return updatedData;
              });
              break;

            case 'MATCH_CANCELLED':
              setMatchData(null);
              setGameStarted(false);
              setStatus('Connected');
              break;
              
            case 'MATCH_STARTING':
              setGameStarted(true);
              break;

            default:
              if (mounted) {
                console.log('Received message:', data);
              }
          }
        };
      } catch (error) {
        if (!mounted) return;
        
        console.error('Error creating WebSocket:', error);
        setConnectionError('Failed to connect to server. Please refresh the page.');
      }
    };

    connectWebSocket();

    return () => {
      mounted = false;
      if (socket) {
        // Close the socket with a normal closure code
        socket.close(1000, "Component unmounting");
      }
    };
  }, [token]);

  useEffect(() => {
    // Initialize the audio element
    matchSoundRef.current = new Audio('/sfx/matchfound.mp3');
    matchSoundRef.current.volume = 0.7;
    
    return () => {
      // Clean up audio when component unmounts
      if (matchSoundRef.current) {
        matchSoundRef.current.pause();
        matchSoundRef.current.src = '';
      }
    };
  }, []);

  // Save sound preference to localStorage when it changes
  useEffect(() => {
    localStorage.setItem('playSoundOnMatch', JSON.stringify(playSoundOnMatch));
  }, [playSoundOnMatch]);

  const handleJoinQueue = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify({ type: 'JOIN_QUEUE' }));
    }
  };

  const handleLeaveQueue = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify({ type: 'LEAVE_QUEUE' }));
    }
  };

  const handleMatchAccept = () => {
    if (socket && socket.readyState === WebSocket.OPEN && matchData) {
      socket.send(JSON.stringify({
        type: 'MATCH_RESPONSE',
        data: {
          matchId: matchData.matchId,
          accepted: true
        }
      }));
    }
  };

  const handleMatchDecline = () => {
    if (socket && socket.readyState === WebSocket.OPEN && matchData) {
      socket.send(JSON.stringify({
        type: 'MATCH_RESPONSE',
        data: {
          matchId: matchData.matchId,
          accepted: false
        }
      }));
      setMatchData(null);
    }
  };

  const handleGameEnd = (gameData) => {
    setGameStarted(false);
    setMatchData(null);
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify({
        type: 'GAME_END',
        data: gameData
      }));
    }
  };

  if (gameStarted && matchData) {
    return <MultiplayerGame socket={socket} matchData={matchData} onGameEnd={handleGameEnd} />;
  }

  return (
    <div className="waiting-room">
      <div className="waiting-room-container">
        <div className="justify-between items-center">
          <Link to="/" className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-200 hover:from-blue-400 hover:to-green-300 transition-colors">
            LangGuesser's
          </Link>
        </div>
        <h2>Multiplayer Mode</h2>
        
        <div className="waiting-room-status">
          {status}
        </div>

        <div className="waiting-room-info">
          <div className="waiting-room-players">
            Players Online: {playersOnline}
          </div>
          <div className="waiting-room-players">
            Players in Queue: {playersInQueue}
          </div>
          <div className="sound-preference mt-4">
            <label className="flex items-center space-x-2 cursor-pointer">
              <input
                type="checkbox"
                checked={playSoundOnMatch}
                onChange={(e) => setPlaySoundOnMatch(e.target.checked)}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <span className="text-sm text-white">Play sound when match is found</span>
            </label>
          </div>
        </div>

        <div className="waiting-room-buttons">
          {!inQueue ? (
            <button 
              className="join-button"
              onClick={handleJoinQueue}
              disabled={status !== 'connected' && !status.startsWith('Connected as')}
            >
              Join Queue
            </button>
          ) : (
            <button 
              className="cancel-button"
              onClick={handleLeaveQueue}
            >
              Leave Queue
            </button>
          )}
        </div>
      </div>
      <p className="text-s text-gray-200 mt-5 bg-black bg-opacity-50 p-0.5 rounded-md">
  🚧 <i><b>Heads up:</b> Multiplayer is still a work in progress, so expect occasional issues.</i>
</p>


      {matchData && (
        <MatchFoundPopup
          matchData={matchData}
          onAccept={handleMatchAccept}
          onDecline={handleMatchDecline}
        />
      )}
      {connectionError && (
        <div className="connection-error">
          {connectionError}
        </div>
      )}
    </div>
  );
};

export default WaitingRoom;
